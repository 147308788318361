import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { UserService } from './@core/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isFiscal: boolean;

  constructor(private user: UserService, private router: Router) { }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
  ngOnInit() {

    const url = window.location.origin.split("//");
    environment.serverHost = environment.serverHost;
    console.info(environment.serverHost);
    if (url[1] === "localhost:4200") {

      environment.serverHost = environment.serverHost;
      environment.realm = environment.acessoPoa.realm;
      // environment.realm = environment.keycloak.realm;
    } else {
      const urlIs = url[1].split(".");
      console.log("UrlIs..............", urlIs[0]);
      if (urlIs[0] === "sgp" || urlIs[0] === "sgp-hom" || (urlIs[0] === 'sgp-web' && urlIs[1] === 'k8s')) {
        environment.realm = environment.acessoPoa.realm;
      }
      if (urlIs[0] === "sgp-admin" || urlIs[0] === "sgp-admin-hom" || (urlIs[0] === 'sgp-admin' && urlIs[1] === 'k8s')) {
        environment.realm = environment.keycloak.realm;
      }
      else {
        environment.realm = environment.acessoPoa.realm;
      }
    }
  }
}
