<div *ngIf="parceriaInfo != undefined">
    <div class="infoGeral container">
        <dl>
            <dd>
                <div>
                    <h2>
                        <a *ngIf="true" ngbTooltip="Voltar" [routerLink]="['/planejamento/exibeParceriaM1']"  [queryParams]="{isAuditorExterno: isAuditorExterno}" class="link">
                            <i class="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i> </a>
                        {{parceriaInfo.parOrgNome}}
                    </h2>
                </div>
            </dd>
        </dl>
        <div *ngIf="parceriaInfo.parId == -1" style="background-color: #ACDBD9; padding-top: 10px; padding-right: 10px; padding-left: 10px; padding-bottom: 15px;">
            <h6>Caso queira copiar os dados de um termo existente, preencha os campos de órgão e número do termo abaixo e clique em "Copiar termo".</h6>
            <div class="row">
                <div class="col-md-5">
                    <div class="columnBold">Órgão:</div>
                    <div class="column">
                        <div class="select-wrapper" style="width: 100%">
                            <ng-select virtualScroll="true" class="form-control" [items]="orgaoList" bindLabel="nomeOrgao" bindValue="id" [(ngModel)]="parceriaInfo.idOrgaoCopiaTermo">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="columnBold">Nº do termo:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parNumCopiaTermo=undefined" title="limpar"></i>
                        <input style="width:100%;" id="parNumCopiaTermo" class="form-control" name="parNumCopiaTermo" #parceriaInfo.parNumCopiaTermo="ngModel" type="text" [(ngModel)]="parceriaInfo.parNumCopiaTermo">
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="column">&nbsp;</div>
                    <br/>
                    <div class="column">
                        <button (click)="copiaTermo() " id="button-copia-termo" class="btn btn-primary btn-info" type="button " title="Copiar termo">
                            Copiar termo
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Procedimento de seleção:</div>
                    <ng-select class="form-control" [items]="utils.listTiposLicitacao" bindLabel="tipo" bindValue="sigla" [(ngModel)]="parceriaInfo.modalidadeLicitacao" 
                    [clearable]="false"></ng-select>
                    <span [hidden]="parceriaInfo.modalidadeLicitacao" class="campoObrigatorio">*</span>
                </div>
                <div class="col-md-3">
                    <div class="columnBold">Nº:</div>
                    <input type="text" class="form-control" [(ngModel)]="parceriaInfo.nLicitacao" [textMask]="maskNumLicitacao" required>
                    <span [hidden]="parceriaInfo.nLicitacao" class="campoObrigatorio">*</span>
                </div>
                <div class="col-md-3">
                    <div class="columnBold">Ano:</div>
                    <input type="text" class="form-control" [(ngModel)]="parceriaInfo.anoLicitacao" [textMask]="maskAnoLicitacao" minlength="4" required>
                    <span [hidden]="parceriaInfo.anoLicitacao" class="campoObrigatorio">*</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-check form-check-inline">
                        <div class="columnBold">Contratado é o vencedor da Licitação?
                            <span [hidden]="parceriaInfo.contratadoVencLicitacao != undefined" class="campoObrigatorio">*</span>
                            <br>
                            <input type="radio" class="form-check-input" [name]="contratadoVencLicitacao" id="true" [value]="true" [(ngModel)]="parceriaInfo.contratadoVencLicitacao">
                            <span for="true" class="form-check-label mr-4">Sim</span>
                            <input type="radio" class="form-check-input" [name]="contratadoVencLicitacao" id="false" [value]="false" [(ngModel)]="parceriaInfo.contratadoVencLicitacao">
                            <span for="false" class="form-check-label">Não</span>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template [ngIf]="!parceriaInfo.contratadoVencLicitacao && parceriaInfo.contratadoVencLicitacao != undefined">
                <div class="row">
                    <div class="col-md-12">
                        <div class="columnBold">Justificativa:</div>
                        <div class="column">
                            <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.justNovoContratadoLicitacao=undefined" title="limpar"></i>
                            <textarea style="width:100%;" class="form-control" [(ngModel)]="parceriaInfo.justNovoContratadoLicitacao" maxlength="300" required></textarea>
                            <span [hidden]=parceriaInfo.justNovoContratadoLicitacao class="campoObrigatorio">*</span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <hr class="class-hr-espaco">

            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">CNPJ Parceira:</div>
                    <div class="column">
                        <div class="input-group">
                            <input type="text" id="parOrgCnpj" class="form-control" name="parOrgCnpj" #parOrgCnpj="ngModel" aria-label="parOrgCnpj" aria-describedby="parOrgCnpj" [(ngModel)]="parceriaInfo.parOrgCnpj" [textMask]="parceriaInfo.parOrgCnpj && parceriaInfo.parOrgCnpj.length < 14 ? mascaraCPF : mascaraCNPJ"
                                (focusout)="buscaPessoa(parceriaInfo.parOrgCnpj)" [disabled]="isAuditorExterno" required>
                            <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgCnpj=undefined" title="limpar"></i>
                            <div class="input-group-append">
                                <button (click)="openModal(contentModal, undefined) " id="button-cpnj " class="btn btn-primary btn-info" type="button " title="Busca por nome"> <i
                                        class="fa fa-search "><span class="texto-botao"></span> </i>
                                </button>
                            </div>
                        </div>
                        <span [hidden]="parceriaInfo.parOrgCnpj" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Nome Parceira:</div>
                    <div class="column">{{parceriaInfo.parOrgNome}}</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="columnBold">Objeto/Finalidade:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgObjeto=undefined" title="limpar"></i>
                        <textarea [disabled]="isAuditorExterno" style="width:100%;" id="parOrgObjeto" class="form-control" ng-model="name" #parOrgObjeto="ngModel" [(ngModel)]="parceriaInfo.parOrgObjeto" required></textarea>
                        <span [hidden]="parceriaInfo.parOrgObjeto" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Órgão ou Entidade PMPA:</div>
                    <div class="column">
                        <div class="select-wrapper" style="width: 100%">
                            <ng-select [disabled]="isAuditorExterno" virtualScroll="true" class="form-control" [items]="orgaoList" bindLabel="nomeOrgao" bindValue="id" [(ngModel)]="orgaoSelecionado" 
                            (change)="updateNomeOrgao($event)"
                            required>

                            </ng-select>
                        </div>
                        <span [hidden]="parceriaInfo.zgororgaoCdorgao2" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Logradouro:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgEndereco=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgEndereco" class="form-control" name="parOrgEndereco" #parOrgEndereco="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgEndereco" required>
                        <span [hidden]="parceriaInfo.parOrgEndereco" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="columnBold">Número:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgNro=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgNro" class="form-control" name="parOrgNro" #parOrgNro="ngModel" [textMask]="mascaraNro" type="text" [(ngModel)]="parceriaInfo.parOrgNro" required>
                        <span [hidden]="parceriaInfo.parOrgNro" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="columnBold">Bairro:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgBairro=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgBairro" class="form-control" name="parOrgBairro" type="text" [(ngModel)]="parceriaInfo.parOrgBairro" required>
                        <span [hidden]="parceriaInfo.parOrgBairro" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Complemento:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgComplemento=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgComplemento" class="form-control" name="parOrgComplemento" 
                            #parOrgCep="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgComplemento" maxlength="35">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Cidade:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parCidade=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parCidade" class="form-control" name="parCidade" #parCidade="ngModel" type="text" [(ngModel)]="parceriaInfo.parCidade" required>
                        <span [hidden]="parceriaInfo.parCidade" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="columnBold">Estado:</div>
                    <div class="column">
                        <ng-select class="form-control" [items]="this.utils.listEstados" bindLabel="sigla" bindValue="sigla" #parOrgUF="ngModel" type="text" 
                            [(ngModel)]="parceriaInfo.parOrgUF" (change)="updateParOrgUF($event)" [clearable]="false" required>
                        </ng-select>
                        <span [hidden]="parceriaInfo.parOrgUF" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="columnBold">CEP:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgCep=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgCep" class="form-control" name="parOrgCep" [textMask]="mascaraCEP" #parOrgCep="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgCep" required>
                        <span [hidden]="parceriaInfo.parOrgCep" class="campoObrigatorio">*</span>
                    </div>
                </div> 
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Email: </div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgEmail=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgEmail" class="form-control" name="parOrgEmail" #parOrgEmail="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgEmail" required emailvalidator>
                        <span [hidden]="parceriaInfo.parOrgEmail" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Email Secundário: </div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgEmail2=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgEmail2" class="form-control" name="parOrgEmail2" #parOrgEmail2="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgEmail2" required emailvalidator>
                        <span [hidden]="parceriaInfo.parOrgEmail2" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Telefone: </div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgTelefoneOSC=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgTelefoneOSC" class="form-control" name="parOrgCelular" [textMask]="mascaraTelFixo" #parOrgTelefoneOSC="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgTelefoneOSC">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Site/Rede Social:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgSiteRedeSocial=undefined" title="limpar"></i>
                        <textarea [disabled]="isAuditorExterno" style="width:100%;" id="parOrgSiteRedeSocial" class="form-control"  name="parOrgSiteRedeSocial" #parOrgSiteRedeSocial="ngModel" [(ngModel)]="parceriaInfo.parOrgSiteRedeSocial"></textarea>

                    </div>
                </div>
            </div>

            <hr class="class-hr-espaco">

            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Nº SEI:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parNroSeimask=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parNroSeimask" class="form-control" name="parNroSeimask" #parNroSeimask="ngModel" maxlength="17" type="text" [(ngModel)]="parceriaInfo.parNroSeimask" required>
                        <span [hidden]="parceriaInfo.parNroSeimask" class="campoObrigatorio">*</span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="columnBold">Nº Termo:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parNum=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parNum" class="form-control" name="parNum" #parNum="ngModel" type="text" [(ngModel)]="parceriaInfo.parNum" required>
                        <span [hidden]="parceriaInfo.parNum" class="campoObrigatorio">*</span>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Classificação:</div>
                    <div class="column">
                        <div class="select-wrapper" style="width: 100%;">
                            <select [disabled]="isAuditorExterno" style="width: 100%;" [ngModel]="parceriaInfo.parClassificacao" name="parClassificacao" #parClassificacao="ngModel" 
                            class="form-control custom-select" (change)="updateClassificacao($event)" required>
                                <option *ngFor="let class of utils.parClassificacaoOption.slice(1)" [value]="class.id">{{class.name}}</option>
                               
                            </select>
                        </div>
                    </div>
                    <span [hidden]="parceriaInfo.parClassificacao" class="campoObrigatorio">*</span>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Nº de Atendidos:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgNmetasAtend=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgNmetasAtend" class="form-control" name="parOrgNmetasAtend" #parOrgNmetasAtend="ngModel" [textMask]="mascaraNro" type="text" [(ngModel)]="parceriaInfo.parOrgNmetasAtend" required>
                        <span [hidden]="parceriaInfo.parOrgNmetasAtend" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <!--<ng-template [ngIf]="parceriaInfo.parClassificacao == 1 || parceriaInfo.parClassificacao == 2">
                <div class="columnBold">Dotações</div>
                <table class="table table-sm" style="font-size: 0.75rem;" id="tabelaDotacoes" [mfData]=" this.parceriaInfo.dotacoes" #mtDotacoesItens="mfDataTable">
                    <thead>
                        <tr>
                            <th>Projeto/Atividade</th>
                            <th>Recurso orçamentário</th>
                            <th>Natureza da despesa</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="vertical-align: middle; margin: 0;" *ngFor="let data of mtDotacoesItens.data">
                            <td><input type="text" class="form-control form-control-sm" 
                                [(ngModel)]="data.projetoAtividade" [ngModelOptions]="{standalone: true}"  maxlength="5" 
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"></td>
                            <td><input type="text" class="form-control form-control-sm" 
                                [(ngModel)]="data.recursoOrcamentario" [ngModelOptions]="{standalone: true}" maxlength="4" 
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"></td>
                            <td><input type="text" class="form-control form-control-sm" 
                                [(ngModel)]="data.naturezaDespesa" [ngModelOptions]="{standalone: true}" maxlength="6" 
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"></td>
                            <td style="vertical-align: middle; font-size:1rem; text-align: center;">
                                &nbsp;<i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true" (click)="excluirDotacaoItem(data)"></i></td>
                
                        </tr>
                    </tbody>
                    <tfoot data-html2canvas-ignore="true">
                        <tr>
                            <td colspan="6"><button class="btn btn-primary btn-sm" (click)="adicionarDotacao()">Incluir Dotação</button></td>
                        </tr>
                    </tfoot>
                </table>
            </ng-template>-->
           
            <hr class="class-hr-espaco">
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Dirigente/Representante Legal: </div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgNomeDirigente=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgNomeDirigente" class="form-control" name="parOrgNomeDirigente" #parOrgNomeDirigente="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgNomeDirigente" required>
                        <span [hidden]="parceriaInfo.parOrgNomeDirigente" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">CPF Dirigente/Representante Legal: </div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgCpfDirigente=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgCpfDirigente" class="form-control" name="parOrgCpfDirigente" #parOrgCpfDirigente="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgCpfDirigente" [textMask]="mascaraCPF" (focusout)="validaCPF(parceriaInfo.parOrgCpfDirigente)"
                            required>
                        <span [hidden]="parceriaInfo.parOrgCpfDirigente" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Telefone do Dirigente/Representante Legal: </div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgTelefone=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgTelefone" class="form-control" name="parOrgTelefone" [textMask]="mascaraTelFixo" #parOrgTelefone="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgTelefone" required>
                        <span [hidden]="parceriaInfo.parOrgTelefone || parceriaInfo.parOrgCelular" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Celular do Dirigente/Representante Legal: </div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgCelular=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgCelular" class="form-control" name="parOrgCelular" [textMask]="mascaraTelCel" #parOrgCelular="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgCelular">
                        <span [hidden]="parceriaInfo.parOrgTelefone || parceriaInfo.parOrgCelular" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Email Dirigente/Representante Legal: </div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgEmailDirigente=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgEmailDirigente" class="form-control" name="parOrgEmailDirigente" #parOrgEmailDirigente="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgEmailDirigente" required emailvalidator>
                        <span [hidden]="parceriaInfo.parOrgEmailDirigente" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Endereço Dirigente/Representante Legal:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgDirigenteEndereco=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgDirigenteEndereco" class="form-control" name="parOrgDirigenteEndereco" #parOrgDirigenteEndereco="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgDirigenteEndereco" required>
                        <span [hidden]="parceriaInfo.parOrgDirigenteEndereco" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Número:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgDirigenteEndNro=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgDirigenteEndNro" class="form-control" name="parOrgDirigenteEndNro" #parOrgDirigenteEndNro="ngModel" [textMask]="mascaraNro" type="text" [(ngModel)]="parceriaInfo.parOrgDirigenteEndNro" required>
                        <span [hidden]="parceriaInfo.parOrgDirigenteEndNro" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Complemento:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgDirigenteCompl=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgDirigenteCompl" class="form-control" name="parOrgDirigenteCompl" #parOrgDirigenteCompl="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgDirigenteCompl">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Bairro:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgDirigenteBairro=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgDirigenteBairro" class="form-control" name="parOrgDirigenteBairro" #parOrgDirigenteBairro="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgDirigenteBairro" required>
                        <span [hidden]="parceriaInfo.parOrgDirigenteBairro" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">CEP do Dirigente/Representante Legal:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgDirigenteCep=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgDirigenteCep" class="form-control" name="parOrgDirigenteCep" [textMask]="mascaraCEP" #parOrgDirigenteCep="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgDirigenteCep" required>
                        <span [hidden]="parceriaInfo.parOrgDirigenteCep" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Cidade:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgDirigenteCidade=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgDirigenteCidade" class="form-control" name="parOrgDirigenteCidade" #parOrgDirigenteCidade="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgDirigenteCidade" required>
                        <span [hidden]="parceriaInfo.parOrgDirigenteCidade" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <hr class="class-hr-espaco">
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Valor mensal do repasse:</div>
                    <div class="column campoValor">
                        <!-- <span [hidden]="parceriaInfo.parOrgVlrMensalRepasse" class="campoObrigatorio">*</span>-->
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgVlrMensalRepasse=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parOrgVlrMensalRepasse" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" name="parOrgVlrMensalRepasse" #parOrgVlrMensalRepasse="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgVlrMensalRepasse"
                            required>
                        <span [hidden]="parceriaInfo.parOrgVlrMensalRepasse" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Valor global inicial:</div>
                    <div class="column campoValor">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parVlrPrevisto=undefined" title="limpar"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%;" id="parVlrPrevisto" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" name="parVlrPrevisto" #parVlrPrevisto="ngModel" type="text" [(ngModel)]="parceriaInfo.parVlrPrevisto" required>
                        <span [hidden]="parceriaInfo.parVlrPrevisto" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <!-- <div class="column-conjunto-valores">
                            <div class="columnBold column-espaco">Valores totais concedidos liberados</div>
                            <div class="column column-valores" style="font-weight:bold; color:#0aaaaf">
                                <div class="row">
                                    <div>
                                        <span [hidden]="parceriaInfo.parOrgVlTotal" class="campoObrigatorio">*</span>
                                        <input style="width:100%;" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" id="parOrgVlTotal" class="form-control" name="parOrgVlTotal" #parOrgVlTotal="ngModel" type="text" [(ngModel)]="parceriaInfo.parOrgVlTotal" required>
                                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parOrgVlTotal=undefined" title="limpar"></i>
                                    </div>
                                </div>
                            </div>
                        </div> -->

            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Data Inicial da Vigência:</div>
                    <div class="column">
                        <i class="fa fa-calendar icon icon_input_date" (click)="dI.toggle()" title="calendário"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%" class="form-control" name="dataIni" #dataIni="ngModel" 
                        [(ngModel)]="parceriaInfo.parOrgDtIniVigenciaFormat" maxlength="10" placeholder="dd/mm/aaaa" appMaskInput 
                        [appMaskValue]="parceriaInfo.parOrgDtIniVigenciaFormat" ngbDatepicker #dI="ngbDatepicker" required>
                        <span [hidden]="parceriaInfo.parOrgDtIniVigenciaFormat" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Data Final da Vigência</div>
                    <div class="column">
                        <i class="fa fa-calendar icon icon_input_date" (click)="dF.toggle()" title="calendário"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%" class="form-control" name="dataFinal" #dataFinal="ngModel" 
                        [(ngModel)]="parceriaInfo.parOrgDtFimVigenciaFormat" ngbDatepicker #dF="ngbDatepicker"
                        maxlength="10" placeholder="dd/mm/aaaa" appMaskInput 
                        [appMaskValue]="parceriaInfo.parOrgDtFimVigenciaFormat" 
                        (dateSelect)="atualizaDataLimitePrestacao()" (focusout)="atualizaDataLimitePrestacao()" required>
                        <span [hidden]="parceriaInfo.parOrgDtFimVigenciaFormat" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="columnBold">Modalidade: </div>
                    <div class="column">
                        <div class="select-wrapper" style="width: 100%">
                            <ng-select [disabled]="isAuditorExterno" [items]="this.utils.modalidadeOptions" class="form-control" bindLabel="name" [(ngModel)]="modalidadeSelecionada" bindValue="id" notFoundText=" não encontrada" (change)="updateModalidade($event)" required>
                            </ng-select>
                        </div>
                        <span [hidden]="parceriaInfo.parOrgModalidade" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Status da Parceria:</div>
                    <div class="column">
                        <div class="select-wrapper" style="width: 100%">
                            <select [disabled]="isAuditorExterno" style="width: 100%" [ngModel]="parceriaInfo.parOrgStatus" name="parOrgStatus" #parOrgStatus="ngModel" class="form-control custom-select" (change)="updateStatus($event)" required>
                                <option [value]="option.id" *ngFor="let option of this.utils.statusParceiraOptions">{{option.name}}
                                </option>
                            </select>
                        </div>
                        <span [hidden]="parceriaInfo.parOrgStatus" class="campoObrigatorio">*</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Prestação de contas final pela OSC:</div>
                    <div class="column">
                        <input [disabled]="isAuditorExterno" [disabled]="true" style="width:100%;" id="parDataLimitePrestacao" class="form-control" name="parceriaInfo.parDataLimitePrestacao" #parceriaInfo.parDataLimitePrestacao="ngModel" type="text" [(ngModel)]="parDataLimitePrestacao" >
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="columnBold">Data limite para análise do órgão:</div>
                    <div class="column">
                        <i class="fa fa-calendar icon icon_input_date" (click)="dLimA.toggle()" title="calendário"></i>
                        <input [disabled]="isAuditorExterno" style="width:100%" class="form-control" name="dataLimiteA" #dataLimiteA="ngModel" 
                        maxlength="10" placeholder="dd/mm/aaaa" appMaskInput 
                        [appMaskValue]="parceriaInfo.parDataLimiteAnaliseFormat" 
                        [(ngModel)]="parceriaInfo.parDataLimiteAnaliseFormat" ngbDatepicker #dLimA="ngbDatepicker" required>
                        <span [hidden]="parceriaInfo.parDataLimiteAnaliseFormat" class="campoObrigatorio">*</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="columnBold">Observação:</div>
                    <div class="column">
                        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="parceriaInfo.parObservacao=undefined" title="limpar"></i>
                        <textarea [disabled]="isAuditorExterno" style="width:100%;" id="parObservacao" class="form-control" ng-model="name" #parObservacao="ngModel" [(ngModel)]="parceriaInfo.parObservacao"></textarea>
                    </div>
                </div>
                <br>
                <br>
            </div>

        </div>
        <app-email-resp-anali-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno">
        </app-email-resp-anali-parceria>
        <br>
        <hr>
        <div *ngIf="!disableCampos()" class="botoes pull-right">
            <button type="button" class="btn btn-default" (click)="cancelar()" [disabled]="isAuditorExterno" title="Cancelar">Cancelar</button>
            <button type="button" class="btn btn-primary" (click)="salvaParceria()"  [disabled]="isAuditorExterno" title="Salvar">Salvar</button>
        </div>
    </div>

</div>


<ng-template #contentModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Busca CNPJ por Nome</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form (ngSubmit)="selecionaCNPJModal()" #edicao="ngForm" novalidate>
        <div class="modal-body">
            <label for="nomeCredor">Nome Parceira</label>
            <div class="input-group">
                <input id="nomeCredor" class="form-control" name="credor" #credor="ngModel" type="text" [(ngModel)]="cnpjTemporario.parOrgNome" (focusout)="buscaPessoa(cnpjTemporario.parOrgNome)" required>
                <i class="fa fa-times icon_input_fontawsome" style="right:105px;" aria-hidden="true" (click)="cnpjTemporario.parOrgNome=undefined" title="limpar"></i>
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary btn-info" (click)="buscaPessoa(cnpjTemporario.parOrgNome)" title="Buscar">
                        <i class="fa fa-search">
                            <span class="texto-botao">Buscar</span></i></button>
                </div>
            </div>

            <div [hidden]="!(loading$ | async) ">
                <div class="loading-overlay "></div>
                <div class="loading-info ">
                    <i class="fa fa-spinner fa-4x fa-spin "></i>
                    <div id="carregando">Aguarde</div>
                </div>
            </div>
            <div *ngIf="pessoaInfoList != undefined && pessoaInfoList.length>0">
                <table class="table table-striped" style="margin-top:30px" id="pessoaInfoList" [mfData]="pessoaInfoList" #mtData="mfDataTable" [mfRowsOnPage]="5">

                    <thead>
                        <tr>
                            <th></th>
                            <th>CNPJ</th>
                            <th>Nome Parceira</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of mtData.data ; let j = index;">
                            <td>
                                <input type="radio" [(ngModel)]="radioSelected" name="list_name" value="{{data.idIdentPessoa}}" (change)="onDataChange(data)" />
                            </td>
                            <td class="col-auto ">
                                {{aplicaMascaraDocumento(data.idIdentPessoa.toString())}}
                            </td>
                            <td class="col-auto ">
                                {{data.nomePessoa}}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="12 ">
                                <div *ngIf="pessoaInfoList.length>5" class="resultPorPagina">Resultados por página:
                                </div>
                                <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>



        <div class="modal-footer col-md-12">
            <button type="button" class="btn btn-default" (click)="c('Close click');" title="Cancelar">Cancelar</button>
            <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid" title="OK">OK</button>
        </div>
    </form>
</ng-template>