<div class="infoGeral container" *ngIf="colaboradores != undefined">
  <div *ngIf="(naoTemPermissao$) | async">
    <h3 class="mt-5 mb-3">Sem Permissão</h3>
    <p>No momento você não tem acesso integral ao Sistema de Gestão de Parcerias. Solicitamos que entre em contato com o órgão responsável pela parceria.
    </p>
</div>
<div *ngIf="loading$ | async">
    <div class="loading-overlay"></div>
    <div class="loading-info">
        <i class="fa fa-spinner fa-4x fa-spin"></i>
        <div id="carregando">Aguarde</div>
    </div>
</div>
  <BR>
  <div *ngIf="(temPermissao$) | async" [hidden]="(cnpjs.length==0)" style="margin-bottom: 20px;"> 
    <div *ngIf="!isFiscal && !isConsulta" > 
      <label for="">Selecione a OSC</label>
      <div class="select-wrapper">
        <select name="selCnpj" #selCnpj="ngModel" class="form-control custom-select" [ngModel]="cnpjs" (change)="setCnpj($event)" required>
          <option *ngFor="let cnpj of cnpjs" [value]="cnpj[0]">{{aplicaMascaraCNPJ(cnpj[0])}} - {{cnpj[1]}}</option>
        </select>
      </div> 
    </div>
  </div>
  <div class="row">
    <div *ngIf="(temPermissao$)">
      <div class="col-md-10">
        <div class="form-group">
          <label for="nome">Nome do colaborador</label>
          <input style="width: 100% " id="nome" name="nomeColaborador" class="form-control " type="text "
                [ngModelOptions]="{standalone: true} "  [(ngModel)]="colaboradorNome" [value]="colaboradorNome"
                (blur)="selectNomeColaborador()" (keydown.enter)="selectNomeColaborador()"
                placeholder="digite alguma palavra-chave">
        </div>
      </div>
      <div class="col-md-2 d-flex align-items-end">
          <button class="btn btn-primary btn-filtro">Filtrar</button>
      </div>    
    </div>
  </div>  
  <div *ngIf="colaboradores.length==0&&cnpj!==undefined">
    <span class="mensagem-vazio">Nenhum colaborador foi encontrado.</span>
  </div>
  <div *ngIf="colaboradores.length>0">
    <div class="columnBold">Colaboradores:</div>
    <table class="table table-striped" style="margin-top:30px" id="tabelaColaboradores" [mfData]="colaboradores"
      #mtColaboradores="mfDataTable" [mfRowsOnPage]="10">
      <thead>
        <tr>
          <th data-html2canvas-ignore="true"></th>
          <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
          <th>Nome</th>
          <th>CPF</th>
          <th>Cargo</th>
          <th>Vínculo</th>
          <th>Sal. Base</th>
          <th>Sal. Bruto</th>
          <th>Resp. OSC</th>
          <th>Anexo</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of mtColaboradores.data; let j = index;">
          <td class="col-auto" data-html2canvas-ignore="true">
            <i ngbTooltip="editar" *ngIf="enableEditarIcone()" class="fa fa-pencil" aria-hidden="true"
              (click)="openEdicao(contentEdicao, data)"></i>
            <i ngbTooltip="visualizar" *ngIf="visualizarBotao()" class="fa fa-search" aria-hidden="true"
              (click)="openEdicao(contentEdicao, data)"></i>
          </td>
          <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
            <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
              (click)="openExcluir(contentExcluir, data)"></i>
          </td>
          <td>{{data.colaboradorNome}}</td>
          <td>{{data.colaboradorCpf}}</td>
          <td>{{data.colaboradorCargo}}</td>
          <td>{{data.colaboradorVinculoTexto}}</td>
          <td>{{data.colaboradorSalarioMensal | currency: 'BRL':true}}</td>
          <td>{{data.colaboradorSalarioBruto | currency: 'BRL':true}}</td>
          <td>
            <div [hidden]="data.colaboradorResponsavelOSC == 0"><i class="fa fa-check " style="color: #089297;"></i>
            </div>
          </td>
          <td>
            <div [hidden]="!data.colaboradorDocumentoArquivo">
              <i ngbTooltip="download {{data.colaboradorDocumentoArquivo}}" class="fa fa-download" aria-hidden="true"
              (click)="downloadColaboradorDocumento(data.id, data.colaboradorDocumentoArquivo, 'D')"
              alt="download"></i>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot data-html2canvas-ignore="true ">
        <tr>
          <td colspan="12 ">
            <div *ngIf="colaboradores.length>10" class="resultPorPagina">Resultados por página:</div>
            <mfBootstrapPaginator [rowsOnPageSet]="[10,20,30]"></mfBootstrapPaginator>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div *ngIf="!disableCampos()">
    <button (click)="openEdicao(contentEdicao, undefined) " id="button-insert_doc " class="btn btn-primary btn-info"
      type="button " title="Adicionar novo" [hidden]="!enableEditarIcone()">
      <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
    </button>
  </div>
  <div *ngIf="disableCampos() && cnpj!==undefined && colaboradores.length>0">
    <button (click)="relatorioColaboradores('PDF') " id="button-lista_pdf " class="btn btn-primary btn-info"
      type="button " title="Baixar lista em PDF">
      <span class="texto-botao">Baixar lista em PDF</span>
    </button>
    &nbsp;&nbsp;&nbsp;
    <button (click)="relatorioColaboradores('XLS') " id="button-lista_xls " class="btn btn-primary btn-info"
      type="button " title="Baixar lista em Excel">
      <span class="texto-botao">Baixar lista em Excel</span>
    </button>
  </div>
  <ng-template #contentEdicao let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Colaborador</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click');cancelar()" title="Fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form #edicao="ngForm" novalidate>
      <div class="modal-body" *ngIf="colaboradorSend">
        <div class="form-group col-md-3">
          <label for="cpf">CPF</label>
          <span [hidden]="cpf.valid" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos()" style="width: 100% " id="cpf" name="cpf" #cpf="ngModel"
            class="form-control " type="text " [ngModelOptions]="{standalone: true} "
            [(ngModel)]="colaboradorSend.colaboradorCpf " [value]="colaboradorSend.colaboradorCpf" (blur)="selectCpf()"
            class="form-control" placeholder="informe o CPF" [textMask]="mascaraCPF" required />
          <i *ngIf="!disableCampos()" class="fa fa-times icon_input_fontawsome " aria-hidden="true "
            (click)="colaboradorSend.colaboradorCpf=undefined; edicao.form.valid=false;" title="limpar "></i>
        </div>
        <div class="form-group col-md-9">
          <label for="nome">Nome</label>
          <span [hidden]="nome.valid" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos()" style="width: 100%" id="nome" name="nome" #nome="ngModel"
            class="form-control" type="text" [ngModelOptions]="{standalone: true}" 
                        [(ngModel)]="colaboradorSend.colaboradorNome" [value]="colaboradorSend.colaboradorNome" required />
          <i *ngIf="!disableCampos()" class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="colaboradorSend.colaboradorNome=undefined;edicao.form.valid=false;" title="limpar"></i>
        </div>
        <div class="form-group col-md-12">
          <label for="endereco">Endereço</label>
          <textarea [disabled]="disableCampos()" ng-d style="width:100%;" id="endereco" class="form-control"
            #endereco="ngModel" [(ngModel)]="colaboradorSend.colaboradorEndereco" [ngModelOptions]="{standalone: true}"
            (change)="updateTextArea($event.target.value)">{{colaboradorSend.colaboradorEndereco}}</textarea>
          <i *ngIf="!disableCampos()" class="fa fa-times icon_input_fontawsome " aria-hidden="true "
            (click)="colaboradorSend.colaboradorEndereco=undefined " title="limpar "></i>
        </div>
        <div class="form-group col-md-12">
          <label for="cargo">Cargo</label>
          <span [hidden]="cargo.valid" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos()" style="width: 100%" id="cargo" name="cargo" #cargo="ngModel"
            class="form-control" type="text" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="colaboradorSend.colaboradorCargo" [value]="colaboradorSend.colaboradorCargo" required />
          <i *ngIf="!disableCampos()" class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="colaboradorSend.colaboradorCargo=undefined;edicao.form.valid=false;" title="limpar"></i>
        </div>

        <div class="form-group col-md-12" >
          <label for="cbo">CBO conforme SEFIP (caso não localize o código na lista, entre em contato com o órgão gestor da parceria)</label>
          <span [hidden]="cbo.valid" class="campoObrigatorio">*</span>
          <ng-select [ngModelOptions]="{standalone: true}" [compareWith]="compareFn" [searchFn]="customSearchFn"
          virtualScroll="true" class="form-control" [items]="cboLista" [(ngModel)]="cboSelecionada" 
            (change)="updateCBO($event)" #cbo="ngModel"
            required>
            <ng-template ng-label-tmp let-item="item">
              {{item.codigo}} - {{item.atividade}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              {{item.codigo}} - {{item.atividade}}
          </ng-template>
            </ng-select>
        </div>

        <div class="form-group col-md-12" >
          <label for="tipoAtividade">Tipo de Atividade Desenvolvida</label>
          <span [hidden]="tipoAtividade.valid" class="campoObrigatorio">*</span>
          <select style="width: 100%" [ngModel]="colaboradorSend.colaboradorTipoAtividade"
          name="tipoAtividade" #tipoAtividade="ngModel" class="form-control form-selec custom-select"
          (change)="updateTipoAtividade($event)">
          <option [value]="option.id" *ngFor="let option of this.utils.tipoAtividade">{{option.name}}
        </select>
        </div>

        <div class="form-group col-md-3">
          <label for="dataAdmissao">Data de admissão</label>
          <span [hidden]="dataAdmissao.valid" class="campoObrigatorio">*</span>
          <input style="width:100%" class="form-control" name="dataAdmissao" #dataAdmissao="ngModel" 
          maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="colaboradorSend.colaboradorDataAdmissaoDataFormat"
            [(ngModel)]="colaboradorSend.colaboradorDataAdmissaoDataFormat" ngbDatepicker #da="ngbDatepicker" [minDate]="{year: 1980, month: 1, day: 1}" [disabled]="disableCampos()" required>
          <i class="fa fa-calendar icon" (click)="da.toggle()" title="calendário"></i>
        </div>
        <div class="form-group col-md-3">
          <label for="dataDemissao">Data de demissão</label>
          <input style="width:100%" class="form-control" name="dataDemissao" #dataDemissao="ngModel"
          maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="colaboradorSend.colaboradorDataDemissaoDataFormat"
            [(ngModel)]="colaboradorSend.colaboradorDataDemissaoDataFormat" ngbDatepicker #dd="ngbDatepicker" [disabled]="disableCampos()" required>
          <i class="fa fa-calendar icon" (click)="dd.toggle()" title="calendário"></i>
        </div>
        <div class="form-group col-md-3">
          <label for="salarioMensal"><abbr title="Refere-se ao salário base definido no contrato de trabalho">Salário base</abbr></label>
          <span [hidden]="colaboradorSend.colaboradorVinculo == 2 || colaboradorSend.colaboradorVinculo == 4 || colaboradorSend.colaboradorVinculo == 6" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos()" style="width: 100%" id="salarioMensal" name="salarioMensal"
            #salarioMensal="ngModel" class="form-control" type="text" currencyMask [ngModelOptions]="{standalone: true}"
            [(ngModel)]="colaboradorSend.colaboradorSalarioMensal"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
            <!--salarioMensal.valid-->
        </div>
        <div class="form-group col-md-3">
          <label for="salarioBruto"><abbr title="Refere-se ao total dos proventos não variáveis, incluindo, por exemplo, insalubridade, quinquênio, etc.">Salário bruto</abbr></label>
          <span [hidden]="colaboradorSend.colaboradorVinculo == 2 || colaboradorSend.colaboradorVinculo == 4 || colaboradorSend.colaboradorVinculo == 6" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos()" style="width: 100%" id="salarioBruto" name="salarioBruto"
            #salarioBruto="ngModel" class="form-control" type="text" currencyMask [ngModelOptions]="{standalone: true}"
            [(ngModel)]="colaboradorSend.colaboradorSalarioBruto"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
        </div>
        <div class="form-group col-md-12">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3" style="height:15px;line-height: .9"
                    [hidden]="colaboradorSend.colaboradorTermoRescisaoNome!=null || disableCampos()">
                    <label for="anexo">Anexo (Termo de Rescisão)</label>
                    <input type="file" name="anexo" id="anexo" class="form-control-upload"
                      accept=".pdf, .gif, .jpg, .jpeg, .png" (change)="onFileChanged($event, 'T')">
          </div>
          <div class="form-group col-md-7" style="height: 17px;" [hidden]="colaboradorSend.colaboradorTermoRescisaoNome==null">
            <label for="">Anexo (Termo de Rescisão)</label>
            <p>
              <a class="link"
                (click)="downloadColaboradorDocumento(colaboradorSend.id, colaboradorSend.colaboradorTermoRescisaoNome,'T')">{{colaboradorSend.colaboradorTermoRescisaoNome}}</a>&nbsp;
              <i *ngIf="!disableCampos()" class="fa fa-times" aria-hidden="true"
                (click)="colaboradorSend.colaboradorTermoRescisaoArquivo=undefined; colaboradorSend.colaboradorTermoRescisaoTipo=undefined; colaboradorSend.colaboradorTermoRescisaoNome = null"
                alt="download"></i>
          </div>


        </div>
        <div class="form-group col-md-6">
          <label for="vinculo">Vínculo</label>
          <span [hidden]="vinculo.valid" class="campoObrigatorio">*</span>
          <div class="select-wrapper">
            <select name="vinculo" #vinculo="ngModel" [ngModel]="colaboradorSend.colaboradorVinculo"
              [disabled]="disableCampos()" style="width: 100%" (change)="selectVinculo($event)"
              class="form-control form-select custom-select" required>
              <option value="1">CLT</option>
              <option value="2">Voluntário</option>
              <option value="3">Estágio remunerado</option>
              <option value="4">Estágio não remunerado</option>
              <option value="5">Menor aprendiz</option>
              <option value="6">Outro</option>
            </select>
          </div>
        </div>
        <div class="form-group col-md-6" [hidden]="colaboradorSend.colaboradorVinculo!=6">
          <label for="vinculoOutro">Qual?</label>
          <span [hidden]="vinculoOutro.valid" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos()" style="width: 100%" id="vinculoOutro" name="vinculoOutro"
            #vinculoOutro="ngModel" class="form-control" type="text" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="colaboradorSend.colaboradorVinculoOutro" [value]="colaboradorSend.colaboradorVinculoOutro"
            required />
          <i *ngIf="!disableCampos()" class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="colaboradorSend.colaboradorVinculoOutro=undefined;" title="limpar"></i>
        </div>
        <div class="form-group col-md-6">
          <label for="nivelEscolaridade">Nível de escolaridade</label>
          <span [hidden]="nivelEscolaridade.valid" class="campoObrigatorio">*</span>
          <div class="select-wrapper">
            <select name="nivelEscolaridade" #nivelEscolaridade="ngModel" style="width: 100%" class="form-control form-select custom-select" [disabled]="disableCampos()" #colaboradorEscolaridade="ngModel"
              [(ngModel)]="colaboradorSend.colaboradorEscolaridade" (change)="selectEscolaridade($event)" required>
              <option value="9">Fundamental Incompleto</option>
              <option value="1">Fundamental</option>
              <option value="2">Médio</option>
              <option value="3">Magistério</option>
              <option value="4">Superior</option>
              <option value="5">Pós-graduação</option>
              <option value="6">Mestrado</option>
              <option value="7">Doutorado</option>
              <option value="8">Pós-doutorado</option>
            </select>
          </div>
        </div>
        <!-- se magisterio -->
        <div>
          <div class="form-group col-md-6" [hidden]="!showUpload || disableCampos()">
            <label for="anexo">Anexo (certificado de conclusão)</label>
            <input type="file" name="anexo" id="anexo" class="form-control-upload"
              accept=".pdf, .gif, .jpg, .jpeg, .png" (change)="onFileChanged($event, 'D')">
          </div>
          <div class="form-group col-md-6" [hidden]="showUpload">
            <label for="">Anexo</label>
            <br/>
            <a class="link" (click)="downloadColaboradorDocumento(colaboradorSend.id, colaboradorSend.colaboradorDocumentoArquivo)">{{colaboradorSend.colaboradorDocumentoArquivo}}</a>&nbsp;
            <i *ngIf="!disableCampos()" class="fa fa-times" aria-hidden="true"
                  (click)="showUpload=true;colaboradorSend.colaboradorDocumentoArquivo=undefined; colaboradorSend.colaboradorDocumentoTipo=undefined; colaboradorDocumento = undefined"
                  alt="download"></i>
          </div>
          <div class="form-group col-md-6">
            <label for="nomeCurso">Nome do curso/formação</label>
            <input type="text" name="nomeCurso" id="nomeCurso" class="form-control" #colaboradorNomeCurso="ngModel" [(ngModel)]="colaboradorSend.colaboradorNomeCurso"
            [value]="colaboradorSend.colaboradorNomeCurso" [ngModelOptions]="{standalone: true}" [disabled]="disableCampos()">
          </div>
          <div class="form-group col-md-4">
            <label for="numRegistro">Nº registro em conselho</label>
            <input type="text" name="numRegistro" id="numRegistro" class="form-control" #colaboradorNumRegConselho="ngModel" [(ngModel)]="colaboradorSend.colaboradorNumRegConselho"
            [value]="colaboradorSend.colaboradorNumRegConselho" [ngModelOptions]="{standalone: true}" [disabled]="disableCampos()">
          </div>
          <div class="form-group col-md-2">
            <label for="dataHabilitacao">Data da habilitação</label>
            <input style="width:100%" class="form-control" name="dataHabilitacao" #dataHabilitacao="ngModel" 
            maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="colaboradorSend.colaboradorDataHabilitacaoDataFormat"
            [(ngModel)]="colaboradorSend.colaboradorDataHabilitacaoDataFormat" [minDate]="{year: 1990, month: 1, day: 1}" ngbDatepicker #dh="ngbDatepicker" [disabled]="disableCampos()" required>
            <i class="fa fa-calendar icon" (click)="dh.toggle()" title="calendário"></i>
          </div>
          <div class="form-group cargaHoraSemana">
            <div class="col-md-12 mb-3">
              <h2 class="h4">Carga horária semanal
                <span *ngIf="!verificaCargaHoraria()" class="campoObrigatorio">*</span>
              </h2>
            </div>
            <table class="table tabela-inputs tabela-tabela" name="cargasHorarias" id="cargasHorarias" [mfData]="colaboradorSend.cargasHorarias"
              #mtCargasHorarias="mfDataTable">
              <thead>
                <tr>
                  <th>Nº do termo de Colaboração
                    <span *ngIf="!verificaCargaHoraria()" class="campoObrigatorio">*</span>
                  </th>
                  <th>Carga horária semanal do Termo selecionado
                    <span *ngIf="!verificaCargaHoraria()" class="campoObrigatorio">*</span>
                  </th>
                  <th>Início da atuação no termo selecionado
                    <span *ngIf="!verificaCargaHoraria()" class="campoObrigatorio">*</span>
                  </th>
                  <th>Fim da atuação no Termo selecionado</th>
                  <th>Turma(s)/escola(s) de atuação</th>
                  <th *ngIf="!disableCampos()"></th>
                </tr>
              </thead>
              <tbody>
                <tr style="vertical-align: middle;" *ngFor="let data of mtCargasHorarias.data">
                  <td>
                    <select name="numTermo"  class="form-control select-tabela" [(ngModel)]="data.parId" 
                      [ngModelOptions]="{standalone: true}" [disabled]="disableCampos()" required>
                      <option *ngFor="let termo of termos" [value]="termo[0]">{{termo[1]}}</option>
                    </select>
                  </td>
                  <td>
                    <input style="width: 100%; height: 100px;" name="nTermo" class="form-control input-tabela" [(ngModel)]="data.cargaHorariaSemanal" 
                      [ngModelOptions]="{standalone: true}" [disabled]="disableCampos()"
                      type="text" [textMask]="mascaraNroHora" required/>
                  </td>
                  <td>
                    <input style="width:100%; height: 100px;" class="form-control input-tabela" name="inicioParceria" 
                    maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="data.dataInicioParceriaDataFormat"
                    [(ngModel)]="data.dataInicioParceriaDataFormat" ngbDatepicker #dip="ngbDatepicker" [ngModelOptions]="{standalone: true}"
                      [disabled]="disableCampos()" required>
                    <i class="fa fa-calendar icon" (click)="dip.toggle()" title="calendário"></i>
                  </td>
                  <td>
                    <input style="width:100%; height: 100px;" class="form-control input-tabela" name="fimParceria" 
                    maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="data.dataFimParceriaDataFormat"
                    [(ngModel)]="data.dataFimParceriaDataFormat" ngbDatepicker #dfp="ngbDatepicker" [ngModelOptions]="{standalone: true}"
                      [disabled]="disableCampos()" required>
                    <i class="fa fa-calendar icon" (click)="dfp.toggle()" title="calendário"></i>
                  </td>
                  <td>
                    <textarea [disabled]="disableCampos()" ng-d id="localAtuacao" class="form-control textarea-tabela" #localAtuacao="ngModel"
                      [(ngModel)]="data.localAtuacao" [ngModelOptions]="{standalone: true}" 
                      (change)="updateTextAreaLocalAtuacao($event.target.value, data)">{{data.localAtuacao}}</textarea>
                  </td>
                  <td *ngIf="!disableCampos()" style="vertical-align: middle;">
                    &nbsp;<i ngbTooltip="apagar" class="fa fa-trash-o" style="font-size: 18px;" aria-hidden="true" (click)="excluirCargaHoraria(data)"></i>
                  </td>
                </tr>
              </tbody>
              <tfoot data-html2canvas-ignore="true">
                <tr>
                  <td colspan="6"><button class="btn btn-primary btn-peq" (click)="adicionarCargaHoraria()" *ngIf="!disableCampos()">Incluir</button></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <!-- fim se magisterio -->

        <div class="form-group col-md-12">
          <input type="checkbox" id="responsavelOSC" name="responsavelOSC"
            [disabled]="disableCampos() || (colaboradorSend.colaboradorVinculo != 1 && colaboradorSend.colaboradorVinculo != 2 && colaboradorSend.colaboradorVinculo != 6)"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="colaboradorSend.colaboradorResponsavelOSCChecked"
            [value]="colaboradorSend.colaboradorResponsavelOSCChecked" (change)="setResponsavelOSC($event)">
          <label for="responsavelOSC">&nbsp;&nbsp;É responsável pela OSC (membro da direção e/ou conselho)</label>
        </div>
        <div [hidden]="colaboradorSend.colaboradorResponsavelOSC == 0" class="form-group col-md-12">
          <div class="form-group col-md-6">
            <label for="colaboradorFuncaoDiretiva">Função diretiva</label>
            <input type="text" style="width: 100%" name="colaboradorFuncaoDiretiva" id="colaboradorFuncaoDiretiva" #colaboradorFuncaoDiretiva="ngModel"
            [(ngModel)]="colaboradorSend.colaboradorFuncaoDiretiva" [value]="colaboradorSend.colaboradorFuncaoDiretiva" [ngModelOptions]="{standalone: true}"
            [disabled]="disableCampos()">
          </div>
          <div class="form-group col-md-3">
            <label for="inicioMandato">Início do mandato</label>
            <input style="width:100%" class="form-control" name="inicioMandato" #fimMandato="ngModel" 
              maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="colaboradorSend.colaboradorInicioMandatoDataFormat"
              [(ngModel)]="colaboradorSend.colaboradorInicioMandatoDataFormat" ngbDatepicker #dim="ngbDatepicker" [disabled]="disableCampos()" required>
            <i class="fa fa-calendar icon" (click)="dim.toggle()" title="calendário"></i>
          </div>
          <div class="form-group col-md-3">
            <label for="fimMandato">Fim do mandato</label>
            <input style="width:100%" class="form-control" name="fimMandato" #fimMandato="ngModel"
              maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="colaboradorSend.colaboradorFimMandatoDataFormat"
              [(ngModel)]="colaboradorSend.colaboradorFimMandatoDataFormat" ngbDatepicker #dfm="ngbDatepicker" [disabled]="disableCampos()" required>
            <i class="fa fa-calendar icon" (click)="dfm.toggle()" title="calendário"></i>
          </div>
        </div>
        <div *ngIf="!visualizarBotao()" class="modal-footer" style="width: 100%;">
          <button type="button" class="btn btn-default"
            (click)="c('Close click');setColaborador(undefined);cancelar()">Cancelar</button>
          <button type="button" class="btn btn-primary"
            ng-disabled="(!edicao.form.valid && !inValidCpf)" (click)="salvaColaborador()">Salvar</button>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #contentExcluir let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Excluir Colaborador</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Tem certeza que deseja excluir o colaborador
        <b>{{colaboradorSend.colaboradorNome}}</b>?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
      <button type="button" class="btn btn-secondary" (click)="excluirColaborador()" title="Excluir">Excluir</button>
    </div>
  </ng-template>
</div>