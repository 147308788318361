import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { RelatoriosApi } from '../../apis/relatoriosApi';
import { ActivatedRoute, Router } from '@angular/router';
import { DateConverter } from 'src/app/@core/date-converter';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { BuscaRelatorioCampos } from '../buscaRelatorioCampos';
import { DatetimeRange } from 'src/app/@shared/components/datepicker/datatime-range';
import { Utils } from 'src/app/@shared/utils';

@Component({
  selector: 'app-relatorio-termo',
  templateUrl: './relatorioTermo.component.html',
  styleUrls: ['../../modulo2/exibeParcerias/exibeParceriasM2.component.scss']
})

export class RelatorioTermoComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  selectedParceriaId: number = null;
  status: number;
  mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
  busca: BuscaRelatorioCampos = new BuscaRelatorioCampos(0, null, null, '', '', '', 0, 0, 0, '', '', '', 0, 25, null, null, "");
  @Input()
  isPortal: boolean;
  @Input()
  modulo: string;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  arquivo: string;
  isAuditorExterno:boolean;
  auditorEmail:string;

  constructor(private alertService: AlertService,
    private relatoriosApi: RelatoriosApi,
    public userService: UserService,
    private dateConverter: DateConverter,
    private router: Router,
    public keycloakService: KeycloakService,
    public utils: Utils,
    private route:ActivatedRoute
  ) {
  }

  ngOnInit() {

    this.modulo = 'relatorio';
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }

    this.isConsulta = this.userService.isConsulta();
    this.isFiscal = this.userService.isFiscal();
    this.route.queryParams.subscribe(params => {
      this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
      this.auditorEmail = this.keycloakService['_keycloakInstance'].tokenParsed.email;
    });


    if (!this.isFiscal && !this.isConsulta &&!this.isAuditorExterno) {
   
      this.router.navigate(['notAuthorized']);
    }
    // pegar qualificador
    this.keycloakService.getUser().subscribe((user: AuthUser) => {

      //console.log(user.attributes.sgp_orgao[0]);
      if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
        // tslint:disable-next-line:no-unused-expression
        if (this.userService.isSMTC()) {
          this.isSMTC = true;
          this.busca = new BuscaRelatorioCampos(0, null, null, '', '', '', 0, 0, 0, '1', '', '', 0, 25, null, null, "");
        } else {
          this.busca = new BuscaRelatorioCampos(Number(user.attributes.sgp_orgao[0]), null, null, '', '', '', 0, 0, 0, '1', '', '', 0, 25, null, null, "");
        }
      }
    });
    if (this.isAuditorExterno){
      this.busca.auditorEmail = this.auditorEmail;
    }
  }
  geraArquivo(tipo) {

    this.busca.tipoArquivo = tipo;
    this.busca.nomeRelatorio = 'termos';
    this.getFile(this.busca);
  }

  getFile(buscaCampos) {

    if (buscaCampos.dataInicial === null && buscaCampos.dataFinal != null) {
      this.alertService.danger('Preencha uma data inicial.');
      return;
    }
    this.loading.next(true);
    this.relatoriosApi.getArquivoRelatorio(buscaCampos)
        .subscribe((response) => {
          if (response) {
            console.log(response);
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(response);
            a.download = `relatorio_${this.busca.nomeRelatorio}.${this.busca.tipoArquivo}`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

          } else {
            this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning("Por favor, use os filtros ou revise os criérios utilizados para limitar a geração do arquivo.");
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
  }

  selectTipoData(event) {
    const newVal = event.currentTarget.value;
    this.busca.tipoData = newVal;
  }
  selectOrgaoParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.orgao = newVal;
  }

  limparFiltros() {
    this.busca.dataInicial = undefined;
    this.busca.dataFinal = undefined;
    this.busca.natureza = 0;
    this.busca.filtroOrdenacao = 0;
    this.busca.nomeOrg = '';
    this.busca.numeroTermo = '';
    this.busca.cnpj = '';
    this.busca.tipoArquivo = '';
    this.busca.tipoData = '1';
  }
  
  novoPeriodo(dataRangeEvent: DatetimeRange) {    
    this.busca.dataInicial = this.dateConverter.fromNgbDateToDate(dataRangeEvent.fromNgbDate);
    this.busca.dataFinal = this.dateConverter.fromNgbDateToDate(dataRangeEvent.toNgbDate);
  }
  selectStatusParceria(event) {
    const newVal = event.currentTarget.value;
// reusando campo para status
    this.busca.natureza = newVal;
  }
}
