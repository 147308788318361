import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DespesaRelatorio } from '../relatorios/desembolso/despesaRelatorio';
import { Colaborador } from '../modulo2/colaboradores/colaborador';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable()
export class RelatoriosApi {
   


    constructor(private http: HttpClient) {
     }

    getDespesaRelatorio(busca) {
        // tslint:disable-next-line:max-line-length
        return this.http.post<Array<DespesaRelatorio>>(`${environment.serverHost}/secure/${environment.realm}/relatorios/despesaRelatorio/busca`, busca);
    }
    getCountDespesaRelatorio(busca) {
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/relatorios/countDespesaRelatorio`, busca);
    }
    getArquivoRelatorio(busca) {
        // tslint:disable-next-line:max-line-length}
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/relatorios/arquivo`, busca, {
                responseType: 'blob',
                headers: new HttpHeaders().append('Content-Type', 'application/json')
              });
    }
    getOscsCombobox(codOrgao) {
        return this.http.get<Array<Array<String>>>(`${environment.serverHost}/secure/${environment.realm}/relatorios/getOscsCombobox?codOrgao=${codOrgao}`);
    }
    getTermosCombobox(busca) {
        return this.http.post<Array<Array<String>>>(`${environment.serverHost}/secure/${environment.realm}/relatorios/getTermosCombobox`, busca);
    }
    getNotificacoesCombobox(busca) {
        return this.http.post<Array<Array<String>>>(`${environment.serverHost}/secure/${environment.realm}/relatorios/getNotificacoesCombobox`, busca);
    }
    getUsuariosCombobox(busca) {
        return this.http.post<Array<String>>(`${environment.serverHost}/secure/${environment.realm}/relatorios/getUsuariosCombobox`, busca);
    }
    getArquivoRelatorioNotificacoes(busca) {
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/relatorios/notificacaoRelatorio`, busca, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
    }
    getNotificacoesComboboxLog(codOrgao,auditorEmail) {
        return this.http.get<Array<Array<Object>>>(`${environment.serverHost}/secure/${environment.realm}/relatorios/getNotificacoesComboboxLog?codOrgao=${codOrgao}&auditorEmail=${auditorEmail}`);
    }
    getArquivoNotificacaoLog(busca) {
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/relatorios/notificacaoLog`, busca, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
    }

    getPriorizacaoPlanilha(busca) {
        // tslint:disable-next-line:max-line-length}
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/relatorios/planilhaPriorizacao`, busca, {
                responseType: 'blob',
                headers: new HttpHeaders().append('Content-Type', 'application/json')
              });
    }

    getListaColaboradoresPlanilha(busca) {
        // tslint:disable-next-line:max-line-length}
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/relatorios/listaColaboradoresPlanilha`, busca, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
          });
    }

    getColaboradorPorCamposCPF(busca) {
        // tslint:disable-next-line:max-line-length}
    
            return this.http.post<Colaborador>(`${environment.serverHost}/secure/${environment.realm}/parcerias/buscaColaboradorPorCamposCPF`, busca);
          
    }    

    getRelatorioGastoPessoal(busca) {
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/relatorios/gastoPessoalRelatorio`, busca, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
    }
}
