import { Pipe, PipeTransform } from '@angular/core';


function getUniqueListBy<T>(items: T[], key: keyof T): T[] {
    const uniqueMap = new Map<any, T>();
    
    items.forEach(item => {
      uniqueMap.set(item[key], item);
    });
    
    return Array.from(uniqueMap.values());
  }
  
  @Pipe({
    name: 'filter'
  })
  export class FilterPipeList<T extends {}> implements PipeTransform {
    transform(items: T[], key: keyof T): T[] {
      return items.length ? getUniqueListBy(items, key) : [];
    }
  }

