<br>
<div id="colaborador" *ngIf="!isFiscal && !isAuditorExterno && !isConsulta && !isPortal">
  <div class="row">
      <div class="col-md-8 d-flex align-items-end">
        <div class="form-group flex-grow-1">
          <label for="nome">Nome do colaborador</label>
          <input style="width: 100% " id="nome" name="nomeColaborador" class="form-control " type="text "
                [ngModelOptions]="{standalone: true} "  [(ngModel)]="colaboradorNome" [value]="colaboradorNome"
                (blur)="selectNomeColaboradorAsync()" (keydown.enter)="selectNomeColaboradorAsync()"
                placeholder="digite alguma palavra-chave">
        </div>
      </div>
      <div class="ml-2">
          <button class="btn btn-primary btn-filtro">Filtrar</button>
      </div>    
  </div>    
<h4 class="mt-3">Colaboradores Cadastrados</h4>
<br>
<div class="row linha-select" style="margin-left: 2px;" *ngIf="depesaParceriaColab.length==0">
  <span class="mensagem-vazio">Nenhum colaborador ativo cadastrado.</span>
</div>

<div class="botao-top" *ngIf="!disableApagarIcone() && depesaParceriaColab.length > 0">
  <button class="btn btn-primary btn-info  mr-5" style="margin-left: -55px;"  (click)="repetirData(1)"><b
      style="color:white; font-size:0.8em !important; " aria-hidden="true">Repetir Data 1ª Linha </b> </button>
  <span style="margin-left: 130px"> </span>
  <button class="btn btn-primary btn-info mr-5" (click)="repetirData(2)"><b
      style="color:white; font-size: 0.8em !important;" aria-hidden="true">Repetir  Data 1ª Linha</b> </button>
</div>
<table class="table table-striped tabela-listagem-edicao" [mfData]="depesaParceriaColab" #md="mfDataTable"
  id="tabelaColaboradoresDespesa">
  <thead>
    <tr>
      <th><input type="checkbox" [(ngModel)]="selectAll" name="selectAll" (click)="selectDespColabAll()"></th>
      <th>Id</th>
      <th>Nome Colaborador</th>
      <th>CPF</th>
      <th>Data Emissão</th>
      <th>Valor Total</th>
      <th>Data Pgto</th>
      <th>Valor Pago</th>
      <th>Documentos</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let despesaColab of md.data | paginate:configColab let idx = index">
      <td class="form-check"><input type="checkbox" [(ngModel)]="despesaColab.limpa" name="limpa"></td>
      <td class="col-auto">{{(idx + ((configColab.currentPage-1) * configColab.itemsPerPage))+1}}</td>
      <td class="col-auto">{{despesaColab.execNomeCredor}}</td>
      <td class="col-auto">{{aplicaMascaraCPF(despesaColab.execNroCpfcnpj)}}</td>
      <td class="col-auto">
        <input style="width:100%;" class="form-control input-tabela" name="dataDocumento" maxlength="10"
          placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="despesaColab.execDocumentoDataFormat"
          [(ngModel)]="despesaColab.execDocumentoDataFormat" ngbDatepicker #dip="ngbDatepicker"
          [ngModelOptions]="{standalone: true}" [disabled]="disableCampos(despesaColab)" required>
        <i class="fa fa-calendar icon" (click)="dip.toggle()" title="calendário"></i>
      </td>
      <td class="col-auto"><input class="form-control input-tabela" [disabled]="disableCampos(despesaColab)" style="width: 100%" currencyMask
          [(ngModel)]="despesaColab.valorTotalDocumento" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          name="valorPagoC" #valorPagoC="ngModel" class="form-control" required validaInputZero />
      </td>
      <td class="col-auto">
        <input class="form-control input-tabela" [disabled]="disableCampos(despesaColab)" style="width: 100%" 
          placeholder="Clique no calendário" name="dataPagtoC" #dataPagtoC="ngModel" maxlength="10"
          [(ngModel)]="despesaColab.dataPagamentoFormat" placeholder="dd/mm/aaaa" appMaskInput
          [appMaskValue]="despesaColab.dataPagamentoFormat" ngbDatepicker #dpC="ngbDatepicker" required>
        <i class="fa fa-calendar icon" (click)="dpC.toggle()" title="calendário"></i>
      </td>
      <td class="col-auto"><input class="form-control input-tabela"  [disabled]="disableCampos(despesaColab)" style="width: 100%" currencyMask
          [(ngModel)]="despesaColab.execValorDoc" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          name="valorDocC" #valorDocC="ngModel" class="form-control" required validaInputZero />
      </td>
      <td class="col-auto">
        <div class="input-tabela" [hidden]="!despesaColab.showUpload  || disableCampos(despesaColab) ">
          <input type="file" style="width:120px" name="anexo1" id="anexo1" class="form-control-upload" accept=".pdf"
            title="Escolha um arquivo" (change)="onFileChanged($event, 'documento', despesaColab)">
        </div>
        <div class="input-tabela" [hidden]="!despesaColab.showUpload1  || disableCampos(despesaColab) ">
          <input type="file" style="width:120px" name="anexo2" id="anexo2" class="form-control-upload" accept=".pdf"
            title="Escolha um arquivo" (change)="onFileChanged($event, 'documento1', despesaColab)">
        </div>
        <div [hidden]="despesaColab.showUpload">
          <i *ngIf="verificaExibicaoAnexo(despesaColab)" ngbTooltip="download {{despesaColab.execDocumentoArquivo}}"
            class="fa fa-download" aria-hidden="true"
            (click)="getArquivo(despesaColab.parId, despesaColab.execId, 'documento',despesaColab.execDocumentoArquivo, true)"
            alt="download"></i>
          <i *ngIf=" !disableCampos(despesaColab)" style="margin-left: 10px;" class="fa fa-times" aria-hidden="true"
            (click)="despesaColab.showUpload= true; despesaColab.execDocumentoArquivo=undefined; despesaColab.execDocumentoTipo= undefined; despesaColab.execDocumento = undefined"
            alt="download"></i>
        </div>
        <div [hidden]="despesaColab.showUpload1">
          <i *ngIf="verificaExibicaoAnexo(despesaColab)" ngbTooltip="download {{despesaColab.execDocumentoArq1}}"
            class="fa fa-download" aria-hidden="true"
            (click)="getArquivo(despesaColab.parId, despesaColab.execId, 'documento1',despesaColab.execDocumentoArq1, true)"
            alt="download"></i>
          <i *ngIf=" !disableCampos(despesaColab)" style="margin-left: 10px;" class="fa fa-times" aria-hidden="true"
            (click)="despesaColab.showUpload1= true; despesaColab.execDocumentoArq1=undefined; despesaColab.execDocumentoTipo1= undefined; despesaColab.execDocumento1 = undefined"
            alt="download"></i>
        </div>

      </td>
    </tr>
  </tbody>

  <tfoot data-html2canvas-ignore="true" *ngIf="depesaParceriaColab.length > configColab.itemsPerPage">
    <tr>
      <td colspan="15" class="p-l-0 p-r-0">
        <div class="d-flex justify-content-between">
          <pagination-controls class="pagination pagination" id="customC" (pageChange)="pageChangedC($event)"
            directionLinks="true" previousLabel="" nextLabel="">
          </pagination-controls>
          <div style="height: 50px;" *ngIf="depesaParceriaColab.length > configColab.itemsPerPage" class="irPara">
            <label for="paginaL">Ir para</label>
            <input style="width: 100%" id="paginaC" name="paginaC" #paginaL="ngModel" [textMask]="mascaraChaveAcesso"
              class="form-control" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="paginaC" size="3"
              [value]="paginaC" (focusout)="consultaPaginaC($event)" (keyup.enter)="consultaPaginaC($event)"
              validaInputZero>
            <i class="fa fa-chevron-right"></i>
          </div>
        </div>
      </td>
    </tr>
  </tfoot>
</table>

<div *ngIf="!disableApagarIcone() && depesaParceriaColab.length>0" class="botao-footer"
  style="margin-top: -10px; justify-content: flex-end; padding: 0;">

  <button class="btn btn-secondary btn-info mr-2" style="background-color: #5fb2bf; border-color:#5fb2bf;"
  (click)="limpaValores()">
  Limpar Registros Marcados</button>

  <button class="btn btn-secondary btn-info mr-2" style="background-color: #5fb2bf; border-color:#5fb2bf;"
    (click)="validaDepesaParceriaColab(true)">
    Salvar Rascunho</button>

  <button class="btn btn-primary btn-info mr-2" (click)="downloadModeloExcell()"><i class="fa fa-download"
      style="color:white" aria-hidden="true"></i> Baixar Modelo</button>

  <button class="btn btn-primary btn-info mr-2" (click)="uploadArq.click()"><i class="fa fa-upload" style="color:white"
      aria-hidden="true"></i> Upload Arquivo
    <input name="uploadArq" type="file" style="display:none" (change)="importaArquivo($event)" #uploadArq>
  </button>
  <button class="btn btn-primary btn-info mr-2" (click)="validaDepesaParceriaColab(false)"> Lançar Despesas</button>
  <!--<button id="button-importLog" class="btn btn-primary btn-info mt-2 mr-2" [hidden]="true"
      (click)="openImportacaoArqDespPgtoPess(contentImportLog)">
    </button> -->
</div>
</div>
<div [hidden]="!(loading$ | async) ">
  <div class="loading-overlay "></div>
  <div class="loading-info ">
    <i class="fa fa-spinner fa-4x fa-spin "></i>
    <div id="carregando">Aguarde</div>
  </div>
</div>
<br>
<br>
<h4>Despesas Lançadas</h4>
<br>
<div class="row linha-select-resultados">
  <span>&ensp;&ensp;</span>
  <div class="col-md-6">
    <div class="texto-linha-select-resultados">Classificar por: &ensp;&ensp;</div>
    <div class="select-wrapper">
      <select [ngModel]="tipo" class="form-control form-select custom-select" (change)="pesquisaDespesaTipo($event)">
        <option value="8">Id</option>
        <option value="0">Data de emissão</option>
        <option value="1">Data de pagamento</option>
        <option value="2">Credor</option>
        <option value="3">Natureza</option>
        <option value="5">Tipo Doc</option>
        <option value="6">Nro Doc</option>
        <option value="4">Valor Pago</option>
        <option value="7">Situacao</option>
      </select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="texto-linha-select-resultados">Filtrar por: &ensp;&ensp;</div>
    <div class="select-wrapper">
      <select [ngModel]="tipoDataEmissaoPagamento" class="form-control form-select custom-select"
        (change)="buscarDespesaPorFiltro($event)">
        <option value="0">Data de emissão</option>
        <option value="1">Data de pagamento</option>
      </select>
    </div>
  </div>
</div>
<div class="row linha-select" *ngIf="depesaParceria.length==0">
  <span class="mensagem-vazio">Nenhuma despesa encontrada para os critérios selecionados.</span>
</div>
<div>
  <div class="linha-select">
    <button (click)="openExportacao(contentExportacao, undefined)" id="button-export" class="btn btn-primary btn-info"
      type="button" title="Download" [hidden]="depesaParceria.length==0">
      <i class="fa fa-download" style="color: white"><span class="texto-botao">Download</span> </i>
    </button>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

    <button (click)="openFiltro(contentFiltro2, undefined)" id="button-filter" class="btn btn-primary btn-info"
      type="button" title="Filtro">
      <span class="texto-botao">Filtro</span>
    </button>
  </div>
  <table class="table table-striped tabela-listagem-edicao" [mfData]="depesaParceria" #mf="mfDataTable"
    id="tabelaDespesa">
    <thead>
      <tr>
        <th data-html2canvas-ignore="true"></th>
        <th data-html2canvas-ignore="true"></th>
        <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
        <th>Id</th>
        <th>CPF/CNPJ</th>
        <th>Credor</th>
        <th>Natureza</th>
        <th>Data Emissão</th>
        <th>Tipo Doc</th>
        <th>Nº</th>
        <th>Valor Pago</th>
        <th>Situação</th>
        <th *ngIf="isFiscal">Login</th>
        <th>Doc</th>
        <th>Doc 2</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let despesa of mf.data | paginate:config let idx = index"
        ngbTooltip="{{despesa.execJustificaExclusao}}"
        [ngClass]="{'strikethrough':despesa.execJustificaExclusao != null}">
        <td class="col-auto" data-html2canvas-ignore="true">
          <img src="../../../../assets/img/list-check.svg" alt="Prestação de contas"
            title="{{despesa.prestacaoContasTexto}}" *ngIf="despesa.prestacaoContasId !== null">
        </td>
        <td class="col-auto" data-html2canvas-ignore="true">
          <i ngbTooltip="editar" *ngIf="enableEditarIcone(despesa)" class="fa fa-pencil" aria-hidden="true"
            (click)="openEdicaoDespesa(contentEdicaoDesembolso, despesa)"></i>
          <i ngbTooltip="visualizar" *ngIf="visualizarBotao(despesa)" class="fa fa-search" aria-hidden="true"
            (click)="openEdicaoDespesa(contentEdicaoDesembolso, despesa)"></i>

        </td>
        <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
          <i ngbTooltip="apagar"
            *ngIf="(despesa.execConferir == 1 || despesa.execConferir == 6) && despesa.execJustificaExclusao  == null"
            class="fa fa-trash-o" aria-hidden="true"
            (click)="openExcluirDespesa(contentExcluirDesembolso, despesa)"></i>
        </td>
        <td class="col-auto">{{despesa.execId}}</td>
        <td class="col-auto">{{despesa.execNroCpfcnpj}}</td>
        <td class="col-auto">{{despesa.execNomeCredor}}</td>
        <td class="col-auto">{{this.utils.getExecNaturezaStr(despesa.execNatureza)}}</td>
        <td class="col-auto">{{despesa.execDocumentoData | date: 'dd/MM/yyyy'}}</td>
        <td class="col-auto">{{this.utils.getExecOperacaoStr(despesa.execOperacao)}}</td>
        <td class="col-auto">{{despesa.execNroDoc}}</td>
        <td class="col-auto">{{despesa.execValorDoc | currency: 'BRL':true}}</td>
        <td class="col-auto"
          [ngClass]="{'vermelho':despesa.execConferir == 3, 'azul':despesa.execConferir == 2, 'amareloVelho':despesa.execConferir == 5, 'laranja':despesa.execConferir == 4, 'roxo':despesa.execConferir == 6}">
          {{this.utils.getExecConferirStr(despesa.execConferir)}}</td>
        <td class="col-auto" *ngIf="isFiscal">{{despesa.operacaoUsuario}}</td>
        <td class="col-auto">
          <div [hidden]="!despesa.execDocumentoArquivo" *ngIf="despesa.execJustificaExclusao == null">
            <i *ngIf="verificaExibicaoAnexo(despesa)" ngbTooltip="download {{despesa.execDocumentoArquivo}}"
              class="fa fa-download" aria-hidden="true"
              (click)="getArquivo(despesa.parId, despesa.execId, 'documento',despesa.execDocumentoArquivo,false)"
              alt="download"></i>
          </div>


          <!-- <i [hidden]="(!despesa.edicao && despesa.execDocumentoArquivo)" class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="resetFile(i)"></i> -->
        </td>
        <td class="col-auto">
          <div [hidden]="!despesa.execDocumentoArq1" *ngIf="despesa.execJustificaExclusao == null">
            <i *ngIf="verificaExibicaoAnexo(despesa)" ngbTooltip="download {{despesa.execDocumentoArq1}}"
              class="fa fa-download" aria-hidden="true"
              (click)="getArquivo(despesa.parId, despesa.execId, 'documento1',despesa.execDocumentoArq1, false)"
              alt="download"></i>
          </div>
          <!-- <i [hidden]="(!despesa.edicao && despesa.execDocumentoArquivo)" class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="resetFile(i)"></i> -->
        </td>
      </tr>
    </tbody>

    <tfoot data-html2canvas-ignore="true" *ngIf="depesaParceria.length > config.itemsPerPage">
      <tr>
        <td colspan="15" class="p-l-0 p-r-0">
          <div class="d-flex justify-content-between">
            <pagination-controls class="pagination pagination" id="custom" (pageChange)="pageChanged($event)"
              directionLinks="true" previousLabel="" nextLabel="">
            </pagination-controls>
            <div *ngIf="depesaParceria.length > config.itemsPerPage" class="irPara">
              <label for="paginaL">Ir para</label>
              <input style="width: 100%" id="paginaL" name="paginaL" #paginaL="ngModel" [textMask]="mascaraChaveAcesso"
                class="form-control" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="pagina" size="3"
                [value]="pagina" (focusout)="consultaPagina($event)" (keyup.enter)="consultaPagina($event)"
                validaInputZero>
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template #contentExportacao let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Exportação dos Desembolsos</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-7">
        <label for="">Selecione o período:</label>
        <div class="form-inline">
          <div class="form-group">
            <input class="form-control" maxlength="10" size="14" name="dataIni" [(ngModel)]="dataIni"
              placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="dataIni" ngbDatepicker #dI="ngbDatepicker">
            <i class="fa fa-calendar icon_input_date" (click)="dI.toggle()" title="calendário"></i>
          </div>
          <div style="width:40px;">
            até
          </div>
          <div class="form-group">
            <input class="form-control" maxlength="10" size="14" name="dataFim" [(ngModel)]="dataFim"
              placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="dataFim" ngbDatepicker #dF="ngbDatepicker">
            <i class="fa fa-calendar icon_input_date" (click)="dF.toggle()" title="calendário"></i>
          </div>
        </div>
        <br>
        <label for="">Classificar por:</label>
        <span style="padding:55px"></span>

        <label for="">Filtrar por:</label>
        <div class="form-inline">
          <div class="form-group">
            <select class="form-control custom-select" name="filtroDataEmissaoPagamento"
              [(ngModel)]="filtroDataEmissaoPagamento">
              <option value="8">Id</option>
              <option value="0">Data de emissão</option>
              <option value="1">Data de pagamento</option>
              <option value="2">Credor</option>
              <option value="3">Natureza</option>
              <option value="5">Tipo Doc</option>
              <option value="6">Nro Doc</option>
              <option value="4">Valor Pago</option>
              <option value="7">Situacao</option>
            </select>
          </div>
          <div style="width:30px;">
          </div>
          <div class="form-group">
            <select class="form-control custom-select" name="tipoDataEmissaoPagamento"
              [(ngModel)]="tipoDataEmissaoPagamento">
              <option value="0">Data de emissão</option>
              <option value="1">Data de pagamento</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="linksDownload">
          <div>
            <a class="link"
              (click)="generatePDF(dataIni, dataFim, filtroDataEmissaoPagamento, tipoDataEmissaoPagamento)"
              title="Baixar arquivo pdf"> <i class="fa fa-file-pdf-o fa-lg"></i> Baixar arquivo PDF</a>
          </div>
          <div>
            <a class="link"
              (click)="exportAs('xlsx', 'despesa', dataIni, dataFim, filtroDataEmissaoPagamento, tipoDataEmissaoPagamento)"
              title="Baixar arquivo excell"> <i class="fa fa-file-excel-o fa-lg"></i> Baixar arquivo Excel</a>
          </div>
          <div>
            <a class="link"
              (click)="exportAs('csv', 'despesa', dataIni, dataFim, filtroDataEmissaoPagamento, tipoDataEmissaoPagamento)"
              title="Baixar arquivo csv"><img src="assets/img/file-csv-solid.svg"> Baixar arquivo csv</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<div *ngIf="!disableApagarIcone()" class="botao-footer">
  <button (click)="openEdicaoDespesa(contentEdicaoDesembolso, undefined)" id="button-insert"
    class="btn btn-primary btn-info mt-2 mr-2" type="button" title="adicionar novo">
    <i class="fa fa-plus"><span class="texto-botao"> Adicionar novo</span></i>
  </button>
</div>

<ng-template #contentEdicaoDesembolso let-c="close" let-d="dismiss">
  <script *ngIf="isPortal" src="https://www.google.com/recaptcha/api.js"></script>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Demonstrativo dos Desembolsos - ID {{despesaSend.execId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [hidden]="!(loading$ | async) ">
    <div class="loading-overlay "></div>
    <div class="loading-info ">
      <i class="fa fa-spinner fa-4x fa-spin "></i>
      <div id="carregando">Aguarde</div>
    </div>
  </div>
  <form (ngSubmit)="salvaDespesa()" #edicaoDespesa="ngForm" novalidate>
    <div class="modal-body" *ngIf="despesaSend">
      <div class="form-row">
        <div class="col-md-4 form-group"
          [ngClass]="{'col-md-12': inscricaoMunicipal.valid || (despesaSend.execOperacao == 9 || despesaSend.execOperacao == 7 || despesaSend.execOperacao == 4 || despesaSend.execOperacao == 16)}">
          <label for="natureza">Natureza</label>
          <span [hidden]="natureza.valid" class="campoObrigatorio">*</span>
          <div class="select-wrapper" style="width: 100%">
            <select [disabled]="disableCampos(despesaSend)" style="width: 100%" [ngModel]="despesaSend.execNatureza"
              name="natureza" #natureza="ngModel" class="form-control form-select custom-select"
              (change)="updateNatureza($event)" required>
              <option [value]="option.id" *ngFor="let option of this.comboNatureza">{{option.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4 form-group" *ngIf="despesaSend.execNatureza==3">
          <label for="tipoServ">Tipo de Serviço de Terceiros</label>
          <span [hidden]="tipoServ.valid" class="campoObrigatorio">*</span>
          <div class="select-wrapper" style="width: 100%">
            <select [disabled]="disableCampos(despesaSend)" style="width: 100%" [ngModel]="despesaSend.tipoDeServico"
              name="tipoServ" #tipoServ="ngModel" class="form-control form-select custom-select"
              (change)="updateTipoServicoTerceiros($event)" required>
              <option [value]="option.id" *ngFor="let option of this.utils.tipoDeServicoTerceiro">{{option.name}}
              </option>
            </select>
          </div>
        </div>
        
        <div class="form-group col-md-4">
          <label for="tipoDoc">Tipo documento</label>
          <span [hidden]="tipoDoc.valid" class="campoObrigatorio">*</span>
          <div class="select-wrapper" style="width: 100%">
            <select [disabled]="disableCampos(despesaSend) || disabledRPCI" style="width: 100%" [ngModel]="despesaSend.execOperacao"
              name="tipoDoc" #tipoDoc="ngModel" class="form-control form-selec custom-select"
              (change)="updateOperacao($event)" required>
              <option [value]="option.id" *ngFor="let option of this.utils.execOperacaoOptions | filter:'name'">{{option.name}}
            </select>
          </div>
        </div>
        <div *ngIf="despesaSend.execOperacao==4 || despesaSend.execOperacao==8 || despesaSend.execOperacao == 16"
          class="form-group col-md-8">
          <label for="chave">Chave de acesso {{despesaSend.execOperacao == 16 ? 'NFS-e' : 'NF-e'}} </label>
          <input [disabled]="disableCampos(despesaSend)" style="width: 100%" id="chave" name="chave" #chave="ngModel"
            [textMask]="(despesaSend.execOperacao==4 || despesaSend.execOperacao==8) ? mascaraChaveAcesso : mascaraChaveAcessoNFSe"
            class="form-control" type="text" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="despesaSend.chaveAcessoNFE" [value]="despesaSend.chaveAcessoNFE" (focusout)="consultaNFeNFSe()"
            required>
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="despesaSend.chaveAcessoNFE=undefined;edicaoDespesa.form.valid=false;" title="limpar"></i>
        </div>
        <div class="form-group col-md-4" [hidden]="despesaSend.execOperacao != 7 && despesaSend.execOperacao != 9">
          <label for="inscricaoMunicipal">Inscrição Municipal (IM)</label>
          <span [hidden]="inscricaoMunicipal.valid || (despesaSend.execOperacao == 4)" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos(despesaSend)" style="width: 100%" id="inscricaoMunicipal"
            name="inscricaoMunicipal" #inscricaoMunicipal="ngModel" class="form-control" type="text"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="despesaSend.execInscricaoMunicipal"
            [value]="despesaSend.execInscricaoMunicipal" required>
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="despesaSend.execInscricaoMunicipal=undefined;edicaoDespesa.form.valid=false;" title="limpar"></i>
        </div>
        <div class="form-group col-md-4" [hidden]="despesaSend.execOperacao==4 || despesaSend.execOperacao == 16">
          <label for="nroDoc">Número documento</label>
          <span [hidden]="nroDoc.valid" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos(despesaSend) || disabledRPCI" style="width: 100%" id="nroDoc" name="nroDoc" #nroDoc="ngModel"
            class="form-control" type="text" [ngModelOptions]="{standalone: true}"
            [textMask]="this.despesaSend.execOperacao !=7 ? mascaraNroDoc:{mask:false}"
            [(ngModel)]="despesaSend.execNroDoc" [value]="despesaSend.execNroDoc" (focusout)="consultaNFSe()" required>
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="despesaSend.execNroDoc=undefined;edicaoDespesa.form.valid=false;" title="limpar"></i>
        </div>
        <div class="col-md-8 form-group" *ngIf="despesaSend.tipoDeServico==1">
          <label for="tipoRPCI">Selecionar RPCI</label>
          <span [hidden]="tipoRPCI.valid" class="campoObrigatorio">*</span>
          <div class="select-wrapper" style="width: 100%">
            <select [disabled]="disableCampos(despesaSend)" style="width: 100%" [ngModel]="servPrestadoPfSelecionado"
              name="tipoRPCI" #tipoRPCI="ngModel" class="form-control form-select custom-select" [ngbTooltip]="tipContent" 
              (change)="updateRPCI($event)" title="{{this.servPrestadoPfSelecionado.name}}" required>
              <option  [value]="option.id"   *ngFor="let option of this.prestadorOption" title="{{option.name}}">{{option.name}}
              </option>
            </select>

          </div>
        </div>
        <div class="col-md-8 form-group" *ngIf="despesaSend.tipoDeServico==2">
          <label for="tipoContrato">Selecionar Contrato</label>
          <span [hidden]="tipoContrato.valid" class="campoObrigatorio">*</span>
          <div class="select-wrapper" style="width: 100%">
            <select [disabled]="disableCampos(despesaSend)" style="width: 100%" [ngModel]="contratoPjSelecionado"
              name="tipoContrato" #tipoContrato="ngModel" class="form-control form-select custom-select"
              (change)="updateContrato($event)" title="{{contratoPjSelecionado.name}}" required>
              <option [value]="option.id" *ngFor="let option of this.prestadorOption" title="{{option.name}}">{{option.name}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row"
        *ngIf="despesaSend.execOperacao!=4 && despesaSend.execOperacao!=7 && despesaSend.execOperacao!= 16">
        <div class="form-group col-md-12 col-lg-4">
          <label for="cpfCnpj">CPF/CNPJ do credor</label>
          <span class="campoObrigatorio">* {{inValidCpfCnpj?' inválido':''}}</span>
          <input [disabled]="(disableCampos(despesaSend) || blockCNPJ || disabledRPCI || despesaSend.tipoDeServico==2)" style="width: 100%" id="cpfCnpj"
            class="form-control" name="cpfCnpj" #cpfCnpj="ngModel" type="text" [(ngModel)]="despesaSend.execNroCpfcnpj"
            [textMask]="despesaSend.execNroCpfcnpj && despesaSend.execNroCpfcnpj.length < 12 ? mascaraCPF : mascaraCNPJ"
            (focusout)="despesaSend.execNomeCredor=buscaPessoa(despesaSend.execNroCpfcnpj);despesaSend.tipoDeServico=getTipoDeServicoPorPessoa(despesaSend.execNroCpfcnpj, parceriaInfo.parOrgCnpj);"
            placeholder="" required>
          <div *ngIf="edicaoDespesa.submitted && cpfCnpj.invalid" class="invalid-feedback">
            <div *ngIf="!cpfCnpj.valid">CNPJ/CPF required</div>
          </div>
          <i *ngIf="disableCampos(despesaSend)" class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="despesaSend.execNroCpfcnpj=undefined; showCamposEdicao=false" title="limpar"></i>
        </div>
        <div class="form-group col-md-12 col-lg-8">
          <label for="nomeCredor">Nome credor</label>
          <span [hidden]="credor.valid || !despesaSend.cnpj9" class="campoObrigatorio">*</span>
          <input style="width:100%;" id="nomeCredor" class="form-control" name="credor" #credor="ngModel" type="text"
            [(ngModel)]="despesaSend.execNomeCredor" [disabled]="!despesaSend.cnpj9 || inValidCpfCnpj" required>
          <i *ngIf="despesaSend.cnpj9" class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="despesaSend.execNomeCredor=undefined" title="limpar"></i>
        </div>
      </div>
      <div class="form-row">
        <div *ngIf="(despesaSend.execOperacao == 7 || despesaSend.execOperacao == 16) && despesaSend.statusCancelamento"
          class="form-group col-md-12">
          <label for="statusCancelamento">Cancelamento</label>
          <input [disabled]="true" style="width: 100%; color: red;" id="statusCancelamento" name="statusCancelamento"
            #statusCancelamento="ngModel" class="form-control" type="text" [ngModelOptions]="{standalone:true}"
            [(ngModel)]="despesaSend.statusCancelamento" [value]="despesaSend.statusCancelamento" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-3"
          *ngIf="despesaSend.execOperacao!=4 && despesaSend.execOperacao != 7 && despesaSend.execOperacao != 16">
          <label for="dataEmissao">Data de emissão</label>
          <span [hidden]="dataEmissao.valid" class="campoObrigatorio">*</span>
          <input
            [disabled]="disableCampos(despesaSend) || despesaSend.execOperacao == 7 || despesaSend.execOperacao == 16"
            style="width: 100%" maxlength="10" class="form-control" name="dataEmissao" placeholder="dd/mm/aaaa"
            appMaskInput [appMaskValue]="despesaSend.execDocumentoDataFormat" #dataEmissao="ngModel"
            [(ngModel)]="despesaSend.execDocumentoDataFormat" required ngbDatepicker #d="ngbDatepicker">

          <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>

        </div>

        <div class="form-group col-md-3"
        *ngIf="despesaSend.execOperacao!=4 && despesaSend.execOperacao != 7 && despesaSend.execOperacao != 16">
        <label for="valorDoc">Valor total documento</label>
        <span [hidden]="valorDoc.valid" class="campoObrigatorio">*</span>
        <input
          [disabled]="disableCampos(despesaSend) || despesaSend.execOperacao == 7 || despesaSend.execOperacao == 16"
          style="width: 100%" currencyMask [(ngModel)]="despesaSend.valorTotalDocumento"
          (focusout)="updateDiferenca()" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="valorDoc"
          #valorDoc="ngModel" class="form-control" required validaInputZero />

      </div>


        <div class="form-group col-md-3">
          <label for="dataPagto">Data do pagamento</label>
          <span [hidden]="dataPagto.valid" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos(despesaSend)" style="width: 100%" class="form-control"
            placeholder="Clique no calendário" name="dataPagto" #dataPagto="ngModel" maxlength="10"
            [(ngModel)]="despesaSend.dataPagamentoFormat" placeholder="dd/mm/aaaa" appMaskInput
            [appMaskValue]="despesaSend.dataPagamentoFormat" ngbDatepicker #dp="ngbDatepicker" required>
          <i class="fa fa-calendar icon" (click)="dp.toggle()" title="calendário"></i>
        </div>
        <div class="form-group col-md-3">
          <label for="valorPago">Valor pago</label>
          <span [hidden]="valorPago.valid" class="campoObrigatorio">*</span>
          <input [disabled]="disableCampos(despesaSend)" style="width: 100%" currencyMask
            [(ngModel)]="despesaSend.execValorDoc" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
            name="valorPago" #valorPago="ngModel" class="form-control" required validaInputZero />
        </div>

      </div>
      <!-- Visualização Nota Produto -->
      <div class="visualizacao-nota"
        [hidden]="despesaSend.execOperacao != 4 || (despesaSend.execOperacao == 4 && nfe.situacao == null)">
        <div class="nota-produto container">
          <div class="row cabecalho">
            <div class="col-md-4 dados-nota">
              <span class="num-nota"> NF-e nº {{nfe.nfe.numero}}</span><span class="serie">| Série
                {{nfe.nfe.serie}}</span><br>
              Modelo: {{nfe.nfe.modelo}}<br>
              Data de emissão: {{nfe.nfe.dataEmissao}}<br>
              R$ {{nfe.nfe.vlrTotalNota}}<br>

              <i class="bi bi-check fa-lg"></i>
              <div [ngClass]="{'nota_cancelada': nfe.situacao ==='Cancelada'}">{{nfe.situacao}}
                <i *ngIf="nfe.situacao ==='Autorizada'" class="fa fa-check"></i>
                <i *ngIf="nfe.situacao ==='Cancelada'" class="fa fa-window-close"></i>
              </div>

            </div>
            <div class="col-md-8 dados-emitente">
              <h1>{{nfe.nfe.emitenteNomeRazaoSocial}}</h1>
              <div class="doc">
                <div>CNPJ: {{aplicaMascaraCNPJ(nfe.nfe.emitenteNroDocumento)}}</div>
                <div>Inscrição estadual: {{nfe.nfe.emitenteInscEstadual}}</div>
              </div>
              <div class="contato">
                <div><span class="label">Endereço:</span> {{nfe.nfe.emitenteEndLgr}}, {{nfe.nfe.emitenteEndNro}},
                  {{nfe.nfe.emitenteEndBairro}}, {{nfe.nfe.emitenteEndCidade}} - {{nfe.nfe.emitenteEndUF}}, CEP
                  {{aplicaMascaraCep(nfe.nfe.emitenteEndCep)}}</div>
                <div><span class="label">Telefone:</span> {{aplicaMascaraTelefone(nfe.nfe.emitenteTelefone)}}</div>
                <div><span class="label">E-mail:</span> {{nfe.nfe.emitenteEmail}}</div>
              </div>
            </div>
          </div>
          <div class="dados-destinatario bloco">
            <h2>Dados do Destinatário</h2>
            <div class="info"><span class="label">CNPJ:</span><span
                class="dado">{{aplicaMascaraCNPJ(nfe.nfe.destinatarioNroDocumento)}}</span></div>
            <div class="info"><span class="label">Nome/Razão Social:</span><span
                class="dado">{{nfe.nfe.destinatarioNomeRazaoSocial}}</span></div>
            <div class="info"><span class="label">Inscrição estadual:</span><span
                class="dado">{{nfe.nfe.destinatarioInscEstadual}}</span></div>
            <div class="info"><span class="label">UF:</span><span class="dado">{{nfe.nfe.destinatarioEndUF}}</span>
            </div>
          </div>
          <div class="dados-produtos-servicos bloco">
            <h2>Dados dos Produtos e Serviços</h2>
            <table class="table table-hover" [hidden]="nfe==null" [mfData]="nfe.nfe.produto" #mf="mfDataTable"
              id="produtos">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Descrição</th>
                  <th>Quantidade</th>
                  <th>Unidade</th>
                  <th class="text-right">Valor unitário</th>
                  <th class="text-right">Valor total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let produto of mf.data ">
                  <td>{{produto.codigo}}</td>
                  <td>{{produto.descricao}}</td>
                  <td>{{produto.tipoUnidade}}</td>
                  <td>{{aplicaMascaraUnidade(produto.quantidade)}}</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(produto.valorUnitario)}}</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(produto.valor)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bloco">
            <h2>Dados dos Totais</h2>
            <div class="info"><span class="label">Valor total dos produtos:</span><span class="dado">R$
                {{aplicaMascaraReais(nfe.nfe.vlrTotalNota)}}</span></div>
            <div class="info"><span class="label">Valor do frete:</span><span class="dado">R$
                {{aplicaMascaraReais(nfe.nfe.valorFrete)}}</span></div>
            <div class="info"><span class="label">Valor total dos descontos:</span><span class="dado">R$
                {{aplicaMascaraReais(nfe.nfe.valorTotalDesconto)}}</span></div>
          </div>
          <div class="bloco">
            <h2>Informações adicionais</h2>
            <div class="info"><span class="dado"> {{nfe.nfe.informacaoAdicional}}</span></div>
          </div>
        </div>
      </div>
      <!-- Fim Visualização Nota Produto -->

      <!-- Visualização Nota Serviço -->
      <div class="visualizacao-nota"
        [hidden]="(despesaSend.execOperacao != 7 && despesaSend.execOperacao != 16) || ((despesaSend.execOperacao == 7 || despesaSend.execOperacao == 16) && nfse.codCnpj == null)">
        <div class="nota-servico container">
          <div class="row cabecalho">
            <div class="col-md-4 dados-nota">
              <span class="num-nota"> NFS-e nº {{despesaSend.execNroDoc}}</span><br>
              Data de emissão: {{nfse.datEmisNfse | date: 'dd/MM/yyyy'}}<br>
              <i class="bi bi-check fa-lg"></i>
              <div [ngClass]="{'nota_cancelada': despesaSend.statusCancelamento}">{{despesaSend.statusCancelamento}}
                <span *ngIf="despesaSend.statusCancelamento == null"> Autorizada</span>
                <i *ngIf="despesaSend.statusCancelamento == null" class="fa fa-check"></i>
                <i *ngIf="despesaSend.statusCancelamento" class="fa fa-window-close"></i>
              </div>
            </div>
            <div class="col-md-8 dados-emitente">
              <h1>{{nfse.nomEmpr}}</h1>
              <div class="doc">
                <div>CNPJ: {{aplicaMascaraCNPJ(nfse.codCnpj)}}</div>
                <div>Inscrição Municipal: {{nfse.codInscMuni}}</div>
              </div>
              <div class="contato">
                <div><span class="label">Endereço:</span> {{nfse.idnEndePres}}{{nfse.idnCidaPres}} CEP: </div>
                <div><span class="label">Telefone:</span>{{nfse.desCnttTeleDcrd}}</div>
                <div><span class="label">E-mail:</span> {{nfse.desCnttEltnDcrd}}</div>
              </div>
            </div>
          </div>
          <div class="dados-destinatario bloco">
            <h2>Dados do Tomador</h2>
            <div class="info flex-50"><span class="label">CNPJ:</span><span
                class="dado">{{aplicaMascaraCNPJ(nfse.ptCodDocuToma)}}</span></div>
            <div class="info flex-50"><span class="label">Inscrição municipal:</span><span
                class="dado">{{nfse.ptCodInscMuni}}</span></div>
            <div class="info flex-50"><span class="label">Nome/Razão Social:</span><span
                class="dado">{{nfse.ptNomToma}}</span></div>
          </div>
          <div class="d-flex">
            <!-- <div class="info"><span class="label">Endereço:</span><span class="dado">Av. Ipiranga, 1200, Bairro?, Porto Alegre - RS, CEP 90400-030</span></div> -->
            <div class="info flex-50"><span class="label">Endereço:</span><span class="dado">{{nfse.ptNomLogr}},
                {{nfse.ptNumImovLogr}} {{nfse.ptDesCompEnde}}, {{nfse.ptNomBair}}</span></div>
            <!--<div class="info"><span class="label">Município:</span><span class="dado">Porto Alegre</span></div>
            <div class="info"><span class="label">UF:</span><span class="dado">{{nfse.ptSigUf}}</span></div>
            <div class="info"><span class="label">CEP:</span><span class="dado">{{nfse.ptNumCep}}</span></div>-->
          </div>
          <!--<div class="d-flex">
            <div class="info flex-50"><span class="label">Telefone:</span><span
                class="dado">{{nfse.desCnttTeleDcrd}}</span></div>
            <div class="info flex-50"><span class="label">E-mail:</span><span class="dado">
                {{nfse.desCnttEltnDcrd}}</span></div>
          </div>-->
          <div class="discriminacao-servico bloco">
            <h2>Discriminação do serviço</h2>
            <div class="info"><span class="dado">{{nfse.desDiscServ}}</span></div>
          </div>
          <div class="dados-nota bloco">
            <h2>Dados da Nota</h2>
            <div class="info"><span class="label">Código de tributação municipal:</span><span
                class="dado">{{nfse.desCnttEltnDcrd}}</span></div>
            <div class="info"><span class="label">Cod/Município da incidência do ISSQN: </span><span
                class="dado">4314902</span></div>
            <div class="info"><span class="label">Natureza da Operação:</span><span
                class="dado">{{nfse.idnNatuOperExt}}</span></div>
            <div class="info"><span class="label">Subitem Lista de Serviços LC 116/03 / Descrição:</span><span
                class="dado">{{nfse.codListServ}} / {{nfse.codListServExt}}.</span></div>
            <div class="info"><span class="label">Regime Especial de Tributação:</span><span
                class="dado">>{{nfse.idnSituEspeExt}}</span></div>
          </div>
          <br>
          <div class="row valor-servicos">
            <div class="col-md-6">
              <table class="table table-hover">
                <tr>
                  <th>Valor dos Serviços</th>
                  <th class="text-right">R$ {{aplicaMascaraReais(nfse.valServ)}}</th>
                </tr>
                <tr>
                  <td>(-) Descontos:</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(nfse.valDescCond)}}</td>
                </tr>
                <tr>
                  <td>(-) Retenções Federais:</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(nfse.valIr != null ? nfse.valIr:0 +
                    nfse.valPis != null ? nfse.valPis:0 + nfse.valCofins != null ? nfse.valCofins: 0 +
                    nfse.valCsll != null ? nfse.valCsll:0 + nfse.valInss != null ? nfse.valInss:0)}}</td>
                </tr>
                <tr>
                  <td>(-) ISS Retido na Fonte:</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(nfse.valIssReti)}}</td>
                </tr>
                <tr class="red">
                  <td>Valor líquido:</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(nfse.valLiqd)}}</td>
                </tr>
              </table>
            </div>
            <div class="col-md-6">
              <table class="table table-hover">
                <tr>
                  <th>Valor dos Serviços</th>
                  <th class="text-right">R$ {{aplicaMascaraReais(nfse.valServ)}}</th>
                </tr>
                <tr>
                  <td>(-) Deduções:</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(nfse.valDedu)}}</td>
                </tr>
                <tr>
                  <td>(-) Desconto Incondicionado:</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(nfse.valDescInco)}}</td>
                </tr>
                <tr>
                  <td><b>(=) Base de Cálculo:</b></td>
                  <td class="text-right"><b>R$ {{aplicaMascaraReais(nfse.valBaseCalc)}}</b></td>
                </tr>
                <tr>
                  <td>(x) Alíquota:</td>
                  <td class="text-right">{{nfse.valAliq*100}}%</td>
                </tr>
                <tr class="red">
                  <td>(=)Valor do ISS:</td>
                  <td class="text-right">R$ {{aplicaMascaraReais(nfse.valIss)}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Fim Visualização Nota Serviço -->

      <div>
        <div class="form-row">
          <div class="form-group col-md-9">
            <label *ngIf="!disableCampos(despesaSend)" for="doc">Documentos<span
                [hidden]="this.despesaSend.execDocumentoArquivo" class="campoObrigatorio">*</span> <small>Somente
                arquivos PDF com no máximo 10MB cada</small></label>
            <div [hidden]="isNFSeOuNFeConveniada()">
              <div [hidden]="despesaSend.showUpload">
                {{despesaSend.execDocumentoArquivo}}
                <i *ngIf="!disableCampos(despesaSend)" class="fa fa-times" aria-hidden="true"
                  (click)="despesaSend.showUpload = true;despesaSend.execDocumentoArquivo=undefined; despesaSend.execDocumentoTipo= undefined; execDocumento = undefined "
                  alt="download"></i>
              </div>
            </div>
          </div>
          <div class="form-group col-md-3" *ngIf="despesaSend.tipoDeServico==2">
            <label for="valorMensalVig">Valor Mensal Vigente</label>
            <span [hidden]="valorMensalVig.valid" class="campoObrigatorio">*</span>
            <input
              [disabled]="disableCampos(despesaSend) || despesaSend.execOperacao == 7 || despesaSend.execOperacao == 16"
              style="width: 100%" currencyMask [(ngModel)]="despesaSend.valorMensalVigenteContrato"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="valorMensalVig"
              #valorMensalVig="ngModel" class="form-control" required validaInputZero />

          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-9">
            <div [hidden]="isNFSeOuNFeConveniada()">
              <!--<div [hidden]="showUpload">
              {{despesaSend.execDocumentoArquivo}}
              <i *ngIf="!disableCampos(despesaSend)" class="fa fa-times" aria-hidden="true"
                (click)="showUpload = true;despesaSend.execDocumentoArquivo=undefined; despesaSend.execDocumentoTipo= undefined; execDocumento = undefined "
                alt="download"></i>
            </div>-->
              <div [hidden]="!despesaSend.showUpload || isFiscal || isPortal">
                <input type="file" name="fileInput{{i}}" accept=".pdf"
                  (change)="onFileChanged($event, 'documento', despesaSend)">
              </div>
            </div>
          </div>
          <div class="form-group col-md-3" *ngIf="despesaSend.tipoDeServico==2">
            <label for="valorDiferenca">Diferença</label>
            <input [disabled]="true" [ngClass]="{'vermelho':valorDiferenca < 0, 'azul':valorDiferenca > 0}"
              style="width: 100%" currencyMask [(ngModel)]="valorDiferenca"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="valorDiferenca" class="form-control" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <!-- <label *ngIf="!disableCampos(despesaSend)" for="doc">Documento 2</label> -->
            <div [hidden]="showUpload1">
              {{despesaSend.execDocumentoArq1}}
              <i *ngIf="!disableCampos(despesaSend)" class="fa fa-times" aria-hidden="true"
                (click)="showUpload1 = true; despesaSend.execDocumentoArq1 = undefined; despesaSend.execDoccumentoTipo1= undefined; execDocumento1 = undefined"
                alt="download"></i>
            </div>
            <div [hidden]="!showUpload1 || isFiscal || isPortal">
              <input type="file" name="fileInput1{{i}}" accept=".pdf"
                (change)="onFileChanged($event, 'documento1', despesaSend)">
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="verificaExibicaoAnexo(despesaSend) ">
          <div class="form-group col-md-12" *ngIf="despesaSend.execId !== -1">
            <div class="select-wrapper">
              <select class="form-control form-select custom-select" [value]='documento' (change)="getDocs($event)">
                <option [selected] value="documento">Documento 1</option>
                <option value="documento1">Documento 2</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12" *ngIf="despesaSend.execId !== -1">

            <ngx-extended-pdf-viewer *ngIf="arquivo && (verificaExibicaoAnexo(despesaSend))" [textLayer]="true"
              [showHandToolButton]="true" [src]="arquivo" useBrowserLocale="true" height="50vh"
              [showZoomButtons]="true"></ngx-extended-pdf-viewer>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="situacao">Situação</label>
            <div class="select-wrapper" style="width: 100%">
              <select [disabled]="(!isFiscal || isSMTC)" style="width: 100%" [ngModel]="despesaSend.execConferir"
                name="situacao" #tipoDoc="ngModel" class="form-control form-select custom-select"
                (change)="updateSituacao($event)" required>
                <option [value]="option.id" *ngFor="let option of this.utils.statusOptions"
                  [ngClass]="{'vermelho':option.id == 3, 'azul':option.id == 2, 'amareloVelho':option.id == 5, 'laranja':option.id == 4, 'roxo':option.id == 6}">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="execDevolucao">Devolução</label>
            <input
              [disabled]="(despesaSend.execConferir != 4 && despesaSend.execConferir != 5) || (!isFiscal || isSMTC)"
              style="width: 100%" currencyMask [(ngModel)]="despesaSend.execDevolucao"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="execDevolucao" #execDevolucao="ngModel"
              class="form-control" required validaInputZero />

          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="motivo">Apontamento</label>
            <textarea [disabled]="isSMTC || !isFiscal" ng-d style="width:100%;" id="motivo" class="form-control"
              #motivo="ngModel" [(ngModel)]="despesaSend.execMotivo" [ngModelOptions]="{standalone: true}"
              (change)="updateTextArea($event.target.value)">{{despesaSend.execMotivo}}</textarea>
            <i *ngIf="!isSMTC && isFiscal" class="fa fa-times icon_input_fontawsome " aria-hidden="true "
              (click)="despesaSend.execMotivo=undefined " title="limpar "></i>

          </div>
        </div>
      </div>
      <div *ngIf="!visualizarBotao(despesaSend)" class="modal-footer" style="width: 100%;">
        <h6 class="modal-title mr-auto" id="modal-basic-title">Demonstrativo dos Desembolsos - ID {{despesaSend.execId}}
        </h6>
        <button type="button" class="btn btn-default"
          (click)="c('Close click');setDespesaSend(undefined)">Cancelar</button>
        <button type="submit" class="btn btn-primary"
          ng-disabled="(!edicaoDespesa.form.valid && !inValidCpfCnpj)">Salvar</button>

      </div>
    </div>
  </form>
</ng-template>
<ng-template #contentExcluirDesembolso let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Excluir demonstrativo dos Desembolsos</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Tem certeza que deseja excluir o demonstrativo dos Desembolsos <b>{{despesaSend.execNroCpfcnpj}} -
        {{despesaSend.execNomeCredor}}</b>?</p>

    <div>
      <label class="form-group col-md-4" for="motivo">Justificativa</label>
      <textarea [disabled]="isSMTC || isFiscal" ng-d style="width:100%;" id="motivo" class="form-control"
        #motivo="ngModel" [(ngModel)]="despesaSend.execJustificaExclusao" [ngModelOptions]="{standalone: true}"
        (change)="updateTextAreaJustificativa($event.target.value)">{{despesaSend.execJustificaExclusao}}</textarea>
      <i class="fa fa-times icon_input_fontawsome " aria-hidden="true "
        (click)="despesaSend.execJustificaExclusao=undefined " title="limpar "></i>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
    <button type="button" class="btn btn-secondary" (click)="excluirDespesa()" title="Excluir">Excluir</button>
  </div>
</ng-template>

<ng-template #contentConfirmaSalvar let-c="close" let-d="dismiss" style="max-width: 500px !important;">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><b>Atenção</b> Existem campos não preenchidos! <br>
      Registros com preenchimento incompleto <b>não</b> serão salvos.
    </p>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Cancelar">Cancelar</button>
    <button type="button" class="btn btn-primary" style="margin-bottom: 5px;" (click)="salvaDepesaParceriaColab(false)"
      title="Prosseguir">Prosseguir</button>
  </div>
</ng-template>
<br>
<br>
<div style="font-size: 15px;">
  <div class="row">
    <div class="col-md-3">Total Consumo</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485,">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execTotVlrConsumo)}}</b></div>
      </div>
    </div>
    <div class="col-md-2">Quantidade:
    </div>
    <div class="col-md-1">{{execTotQtdConsumo}}
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">Total Pagamento de Pessoal</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execTotVlrPF)}}</b></div>
      </div>
    </div>
    <div class="col-md-2">Quantidade</div>
    <div class="col-md-1">
      <div>{{execTotQtdPF}}</div>
    </div>
    <div *ngIf="!isPortal" class="col-md-2">Portal Transparência</div>
    <div *ngIf="!isPortal" class="col-md-2">
      Dado Sigiloso
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">Total Serviços de Terceiros</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execTotVlrPJ)}}</b></div>
      </div>
    </div>
    <div class="col-md-2">Quantidade</div>
    <div class="col-md-1">
      <div>{{execTotQtdPJ}}</div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-3">Total Outros</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execTotVlrOutros)}}</b></div>
      </div>
    </div>
    <div class="col-md-2">Quantidade</div>
    <div class="col-md-1">
      <div>{{execTotQtdOutros}}</div>
    </div>
  </div>

  <div class="row" *ngIf="isSaude">
    <div class="col-md-3">Total Outras Despesas Pessoal</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execTotVlrOutrasDP)}}</b></div>
      </div>
    </div>
    <div class="col-md-2">Quantidade</div>
    <div class="col-md-1">
      <div>{{execTotQtdOutrasDP}}</div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">Total Permanentes</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execTotVlrPerm)}}</b></div>
      </div>
    </div>
    <div class="col-md-2">Quantidade</div>
    <div class="col-md-1">
      <div>{{execTotQtdPerm}}</div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">Total dos Desembolsos</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execTotVlr)}}</b></div>
      </div>
    </div>
    <div class="col-md-2">Quantidade</div>
    <div class="col-md-1">
      <div>{{execTotQtd}}</div>
    </div>
  </div>

  <ng-template #contentFiltro2 let-ca="close('a')" let-cb="close('b')" let-d="dismiss()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Filtrar Desembolsos por</h4>
      <button type="button" class="close" aria-label="Close" (click)="ca" title="fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-3">
          <label for="nroDoc">Id</label>
          <span [hidden]="nroDoc.valid" class="campoObrigatorio">*</span>
          <input style="width: 100%" id="execId" name="execId" #execId="ngModel" class="form-control" type="text"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="filtro.execId" [value]="filtro.execId">
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="filtro.execId=undefined;edicaoDespesa.form.valid=false;" title="limpar"></i>
        </div>
        <div class="form-group col-md-3">
          <label for="tipoDoc">Tipo documento</label>
          <div class="select-wrapper" style="width: 100%">
            <ng-select [items]="this.utils.execOperacaoOptions" class="form-control" bindLabel="name"
              [(ngModel)]="filtro.execOperacao" bindValue="id" notFoundText=" não encontrado"
              (change)="updateOperacaoFiltro($event)">
            </ng-select>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="nroDoc">Número documento</label>
          <span [hidden]="nroDoc.valid" class="campoObrigatorio">*</span>
          <input style="width: 100%" id="nroDoc" name="nroDoc" #nroDoc="ngModel" class="form-control" type="text"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="filtro.execNroDoc" [value]="filtro.execNroDoc">
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="filtro.execNroDoc=undefined;edicaoDespesa.form.valid=false;" title="limpar"></i>
        </div>
        <div class="form-group col-md-3">

          <label for="nomeCredor">Natureza</label>
          <span [hidden]="natureza.valid" class="campoObrigatorio">*</span>
          <div class="select-wrapper" style="width: 100%">
            <ng-select [items]="this.comboNatureza" class="form-control" bindLabel="name"
              [(ngModel)]="filtro.execNatureza" bindValue="id" notFoundText=" não encontrado" name="natureza"
              #natureza="ngModel" (change)="updateNaturezaFiltro($event)">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-2 col-lg-6">
          <label for="cpfCnpj">CPF/CNPJ do credor</label>
          <input style="width: 100%" id="cpfCnpjF" class="form-control" name="cpfCnpjF" #cpfCnpjF="ngModel" type="text"
            [(ngModel)]="filtro.execNroCpfcnpj"
            [textMask]="filtro.execNroCpfcnpj && filtro.execNroCpfcnpj.length < 12 ? mascaraCPF : mascaraCNPJ"
            (focusout)="filtro.execNomeCredor=buscaPessoaFiltro(filtro.execNroCpfcnpj)" placeholder="">

          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
            (click)="filtro.execNroCpfcnpj=undefined; showCamposEdicao=false" title="limpar"></i>
        </div>
        <div class="form-group col-md-2 col-lg-6">
          <label for="nomeCredor">Nome credor</label>
          <input style="width:100%;" id="nomeCredorF" class="form-control" name="credorF" #credorF="ngModel" type="text"
            [(ngModel)]="filtro.execNomeCredor" [disabled]="true">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <label for="dataEmissao">Data de emissão</label>
          <input style="width: 100%" maxlength="10" class="form-control" name="dataEmissaoF" placeholder="dd/mm/aaaa"
            appMaskInput [appMaskValue]="filtro.execDocumentoDataFormat" #dataEmissaoF="ngModel"
            [(ngModel)]="filtro.execDocumentoDataFormat" required ngbDatepicker #d="ngbDatepicker">

          <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>

        </div>
        <div class="form-group col-md-3">
          <label for="valorDoc">Valor total documento</label>
          <input style="width: 100%" currencyMask [(ngModel)]="filtro.valorTotalDocumento"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="valorDocF" #valorDocF="ngModel"
            class="form-control" required validaInputZero />

        </div>
        <div class="form-group col-md-3">
          <label for="dataPagto">Data do pagamento</label>
          <input style="width: 100%" class="form-control" placeholder="Clique no calendário" name="dataPagtoF"
            #dataPagtoF="ngModel" maxlength="10" [(ngModel)]="filtro.dataPagamentoFormat" placeholder="dd/mm/aaaa"
            appMaskInput [appMaskValue]="filtro.dataPagamentoFormat" ngbDatepicker #dp="ngbDatepicker">
          <i class="fa fa-calendar icon" (click)="dp.toggle()" title="calendário"></i>
        </div>
        <div class="form-group col-md-3">
          <label for="valorPago">Valor pago</label>
          <input style="width: 100%" currencyMask [(ngModel)]="filtro.execValorDoc"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="valorPagoF" #valorPagoF="ngModel"
            class="form-control" validaInputZero />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-3">
          <label for="execConferir">Situação</label>
          <div class="select-wrapper" style="width: 100%">
            <ng-select [items]="this.utils.statusOptions" class="form-control" bindLabel="name"
              [(ngModel)]="filtro.execConferir" bindValue="id" notFoundText=" não encontrado"
              (change)="updateConferir($event)">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="width: 100%;">
        <button type="button" class="btn btn-primary" (click)="aplicaFiltro()" title="Aplicar">Aplicar</button>
      </div>
    </div>
  </ng-template>
  <ng-template #contentImportLog let-c="close" let-d="dismiss">
    <script *ngIf="isPortal" src="https://www.google.com/recaptcha/api.js"></script>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Log de Inconsistências do Arquivo </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="dados-produtos-servicos bloco">
        <table class="table table-striped table-hover" [mfData]="logErroArquivo" #mf="mfDataTable" [mfRowsOnPage]="10"
          id="logE">
          <thead>
            <tr>
              <th>#</th>
              <th>Descrição Erro</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let erro of mf.data;  let idx = index">
              <td>{{(idx+1) + (pageBucket)}}</td>
              <td>{{erro}}</td>
            </tr>
          </tbody>
          <tfoot data-html2canvas-ignore="true ">
            <tr>
              <td colspan="12 ">
                <div *ngIf="logErroArquivo.length>10" class="resultPorPagina">Resultados por página:</div>
                <mfBootstrapPaginator [rowsOnPageSet]="[10,20,30]" (click)="onPageChange(mf)"></mfBootstrapPaginator>
              </td>
            </tr>
          </tfoot>

        </table>

      </div>

      <div class="modal-footer" style="width: 100%;">
        <button type="button" class="btn btn-primary" style="margin-top: 0.99ex" (click)="c('Close click');atualizaTela()"
          title="Fechar e prosseguir">Fechar e prosseguir</button>
        <button type="button" class="btn btn-primary" (click)="generateLogDeErroPDF()" title="Baixar lista de inconsistências"><i
            class="fa fa-download" style="color:white" aria-hidden="true"></i> Baixar lista de inconsistências</button>
      </div>
    </div>
  </ng-template>

  <br>