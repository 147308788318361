import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { RelatoriosApi } from '../../apis/relatoriosApi';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';
import { Utils } from 'src/app/@shared/utils';
import { ListaColaboradoresPlanilhaCampos } from './listaColaboradoresPlanilhaCampos';
import { Colaborador } from '../../modulo2/colaboradores/colaborador';


@Component({
    selector: 'app-lista-colaboradores-planilha',
    templateUrl: './listaColaboradoresPlanilha.component.html',
    styleUrls: ['../../modulo2/exibeParcerias/exibeParceriasM2.component.scss']
})
export class ListaColaboradoresPlanilhaComponent implements OnInit {
    mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
    mascaraCPF = { mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], guide: false };
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    busca: ListaColaboradoresPlanilhaCampos;
    @Input()
    isPortal: boolean;
    @Input()
    modulo: string;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    isAuditorExterno:boolean;
    auditorEmail:string;
    colaborador: Colaborador;
    dataAdmissaoStr: string;
    dataDemissaoStr: string;
    dataHabilitacaoStr: string;


    constructor(private alertService: AlertService,
        private relatoriosApi: RelatoriosApi,
        private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        public userService: UserService,
        private dateConverter: DateConverter,
        private router: Router,
        public keycloakService: KeycloakService,
        public utils: Utils,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.modulo = 'relatorio';
        if (this.isPortal === undefined) {
            this.isPortal = false;
        }

        this.isConsulta = this.userService.isConsulta();
        this.isFiscal = this.userService.isFiscal();
        this.route.queryParams.subscribe(params => {
            this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
            this.auditorEmail = this.keycloakService['_keycloakInstance'].tokenParsed.email;
          });
      
      
          if (!this.isFiscal && !this.isConsulta &&!this.isAuditorExterno) {
            this.router.navigate(['notAuthorized']);
        }

        this.keycloakService.getUser().subscribe((user: AuthUser) => {

            //console.log(user.attributes.sgp_orgao[0]);
            if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                    this.isSMTC = true;
                    this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, null, "");
                } else {
                    this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, Number(user.attributes.sgp_orgao[0]), "");
                }
            }
        });

        if (this.isAuditorExterno){
            this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, null, this.auditorEmail);
        }
    }

    selectOrgaoParceria(event) {
        const newVal = event.currentTarget.value;
        if (newVal !== undefined) {
            this.busca.idOrgao = newVal;
        } else {
            this.busca.idOrgao = null;
        }
    }

    selectModalidade(event) {
        const newVal = event.currentTarget.value;
        if (newVal !== undefined) {
            this.busca.modalidade = newVal;
        } else {
            this.busca.modalidade = null;
        }
    }

    selectStatusParceria(event) {
        const newVal = event.currentTarget.value;
        if (newVal !== undefined) {
            this.busca.statusParceria = newVal;
        } else {
            this.busca.statusParceria = null;
        }
    }

    limparFiltros() {
        this.keycloakService.getUser().subscribe((user: AuthUser) => {

            //console.log(user.attributes.sgp_orgao[0]);
            if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                    this.isSMTC = true;
                    this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, null, "");
                } else {
                    this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, Number(user.attributes.sgp_orgao[0]), "");
                }
            }
        });
        this.busca.auditorEmail = this.auditorEmail;
    }

    geraArquivo() {
        this.loading.next(true);
        if (this.busca.cnpjOsc !== null) {
            this.busca.cnpjOsc = this.busca.cnpjOsc.replace(/[^\d]+/g, '');
        }
        if (this.busca.cpfFuncionario !== null) {
            this.busca.cpfFuncionario = this.busca.cpfFuncionario.replace(/[^\d]+/g, '');
        }
        this.relatoriosApi.getListaColaboradoresPlanilha(this.busca)
            .subscribe((response) => {
                if (response) {
                    console.log(response);
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(response);
                    a.download = `relatorio_colaboradores.xls`;
                    a.href = url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);

                } else {
                    this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
    }

    onCPFChange() {
        // Verifica se o CPF está preenchido (por exemplo, com no mínimo 11 caracteres)
        if (this.busca.cpfFuncionario && (this.busca.cpfFuncionario.length === 11 || this.busca.cpfFuncionario.length === 14)) {
          this.buscaColaboradorSePreencherCPF();
        } else {
          // Se o CPF não estiver preenchido corretamente, pode limpar as informações
          this.colaborador = null;
        }
      }

    buscaColaboradorSePreencherCPF() {
        this.loading.next(true);
        this.colaborador = null;
        var cpfFuncionarioAnt = this.busca.cpfFuncionario;
        if (this.busca.cnpjOsc !== null) {
            this.busca.cnpjOsc = this.busca.cnpjOsc.replace(/[^\d]+/g, '');
        }
        if (this.busca.cpfFuncionario !== null) {
            this.busca.cpfFuncionario = this.busca.cpfFuncionario.replace(/[^\d]+/g, ''); // Tira máscara do CPF
        }
 
        this.relatoriosApi.getColaboradorPorCamposCPF(this.busca).subscribe((response) => {
                if (response) {
                    this.colaborador = response as Colaborador;

                    if (this.colaborador.colaboradorDataAdmissao) {
                        const dateAdmObj = new Date(this.colaborador.colaboradorDataAdmissao);
                        const ngbDateAdmStruct = this.dateConverter.fromDateToNgbDateStruct(dateAdmObj);
                        this.dataAdmissaoStr = this.dateConverter.fromNgbDateStructToString(ngbDateAdmStruct);

                    }
                    if (this.colaborador.colaboradorDataDemissao) {
                        const dateDemObj = new Date(this.colaborador.colaboradorDataDemissao);
                        const ngbDateDemStruct = this.dateConverter.fromDateToNgbDateStruct(dateDemObj);
                        this.dataDemissaoStr = this.dateConverter.fromNgbDateStructToString(ngbDateDemStruct);
                    }
                    if (this.colaborador.colaboradorDataHabilitacao) {
                        const dateHabObj = new Date(this.colaborador.colaboradorDataHabilitacao);
                        const ngbDateHabStruct = this.dateConverter.fromDateToNgbDateStruct(dateHabObj);
                        this.dataHabilitacaoStr = this.dateConverter.fromNgbDateStructToString(ngbDateHabStruct);
                    }
                               

                } else {
                    this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
                }

                this.busca.cpfFuncionario = cpfFuncionarioAnt; // Recupera a máscara do CPF

            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));        
        
        
    }

    formatarCPF(cpf: string): string {
        if (!cpf) return '';
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }
      
      // Método para formatar CNPJ (XX.XXX.XXX/XXXX-XX)
      formatarCNPJ(cnpj: string): string {
        if (!cnpj) return '';
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }    

      getEscolaridadePorExtenso(escolaridade: number): string {
        const escolaridadeMap = {
          9: 'Fundamental Incompleto',
          1: 'Fundamental',
          2: 'Médio',
          3: 'Magistério',
          4: 'Superior',
          5: 'Pós-graduação',
          6: 'Mestrado',
          7: 'Doutorado',
          8: 'Pós-doutorado'
        };
      
        return escolaridadeMap[escolaridade] || 'Não informado';
      }      

      getVinculoPorExtenso(): string {

      switch (this.colaborador.colaboradorVinculo) {
        case 1: return "CLT";
            break;
        case 2: return "Voluntário";
            break;
        case 3: return "Estágio remunerado";
            break;
        case 4: return "Estágio não remunerado";
            break;
        case 5: return "Menor aprendiz";
            break;
        case 6: return this.colaborador.colaboradorVinculoOutro;
            break;
        }

      }
    
}