import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { Mascaras } from '../../../@shared/components/Mascaras';
import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import { UserService } from '../../../@core/user.service';
import saveAs from 'file-saver';
import * as XLSX from 'xlsx';
import { ParceriaInfo } from '../parceriaInfo';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';
import { DateConverter } from 'src/app/@core/date-converter';
import { ResumoFluxoFinanceiro } from './resumoFluxoFinanceiro';
import { AlertService } from 'src/app/@core/alert.service';
import { ImagensUrlData } from 'src/app/@shared/imagens-urldata';
import { DatePipe } from '@angular/common';
import { PesquisaData } from '../../pesquisaData';

@Component({
  selector: 'app-fluxo-parceria',
  templateUrl: './resumoFluxoFinanceiro.component.html',
  styleUrls: ['./../detalheParceria.component.scss'],
  providers: [DatePipe]
})
export class ResumoFluxoFinanceiroComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  @Input()
  public parceriaInfo: ParceriaInfo;
  @Input()
  isPortal: boolean;
  @Input()
  pesquisaData: PesquisaData;
  modalEdicaoReference: any;
  dataIni: NgbDateStruct;
  dataFim: NgbDateStruct;
  resumo: ResumoFluxoFinanceiro;
  tipo: string;

  constructor(public userService: UserService,
    private mascaras: Mascaras,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private alertService: AlertService,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.parceriaInfo.totalDesembolsos = (this.parceriaInfo.execTotVlrPerm + this.parceriaInfo.execTotVlrOutros +
      this.parceriaInfo.execTotVlrPF + this.parceriaInfo.execTotVlrPJ + this.parceriaInfo.execTotVlrConsumo);
      //this.tipo ='1';
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {

      if (changes.hasOwnProperty(property) && property === 'pesquisaData') {
        if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined || this.pesquisaData.dataInicial === null) {
          const myDate = new Date(1970, 0, 1, 0, 0, 0, 0);
          this.pesquisaData = new PesquisaData();
          this.pesquisaData.dataInicial = myDate;
          this.pesquisaData.dataFinal = new Date();
        }
        if (this.pesquisaData.dataFinal === undefined || this.pesquisaData.dataFinal === null) {
          this.pesquisaData.dataFinal = new Date();
        }

        if (this.pesquisaData.dataInicial != null && this.pesquisaData.dataFinal != null) {
          const dtIni = this.dateConverter.fromDateToNgbDateStruct(this.pesquisaData.dataInicial);
          const dtFim = this.dateConverter.fromDateToNgbDateStruct(this.pesquisaData.dataFinal);
          await this.getResumoPorPeriodo(dtIni, dtFim, 1);
        }

        this.dataIni = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.pesquisaData.dataInicial));
        this.dataFim = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.pesquisaData.dataFinal));
      }
    }
  }

  replacer(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['totalDesembolsos', 'quantidadeTotal'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }
  async exportAs(type, dtIni, dtFim, tipo) {

    await this.getResumoPorPeriodo(dtIni, dtFim, tipo);
    // download the file using old school javascript method
    let nome;
    nome = 'Fluxo' + this.parceriaInfo.parOrgCnpj;

    let csvString = '';
    csvString = 'Total dos Ingressos, Total dos Desembolsos, Desembolsos Consumo, Desembolsos Serviços PF, Desembolsos Serviços PJ, Desembolsos Perm, Desembolsos Outros, Lançamentos de Devolução de Aplicações, Lançamentos de Devolução de Recursos, Lançamentos de Devolução para C\/C parceria, Lançamentos de Rendimentos de conta poupança\/provisão, Lançamentos de Rendimentos de outras contas\/aplicações financeiras, Saldo Atual,';
    csvString = csvString.substring(0, csvString.length - 1);
    csvString = csvString + '\n';
    csvString = csvString + this.resumo.execTotIngressos + ',' +
       + this.resumo.totalDesembolsos + ',' + this.resumo.execTotVlrConsumo
       + ',' + this.resumo.execTotVlrPF + ',' + this.resumo.execTotVlrPJ + ','
       + this.resumo.execTotVlrPerm + ',' + this.resumo.execTotVlrOutros
       + ',' + this.resumo.execDevolAplic + ',' + this.resumo.execDevolRecursos
       + ',' + this.resumo.execDevolParceria + ',' + this.resumo.execSaldoFinal + ',' + this.resumo.execDevolPoupanca + ',' + this.resumo.execDevolOutrasContas;

    csvString = csvString.substring(0, csvString.length - 1);
    csvString = csvString + '\n';
    let blob = new Blob([csvString], { type: 'text/csv' });

    if (type === 'csv') {
      saveAs(blob, nome + '.csv');
    }
    else {
        this.csvToExcel(csvString, nome);
    }
  }

  private csvToExcel(csvString: string, nome: any) {
    csvString = csvString.replace(/"|'/g, '');
    let allTextLines = csvString.split(/\r|\n|\r/);
    let headers = allTextLines[0].split(',');
    let lines = [];
    for (let i = 0; i < allTextLines.length; i++) {
      let data = allTextLines[i].split(',');
      if (data.length === headers.length) {
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          if (i > 0) {
            let dado = parseFloat(data[j]);
            tarr.push(dado);
          } else {
            tarr.push(data[j]);
          }
        }
        lines.push(tarr);
      }
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lines);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, nome + '.xlsx');
  }

  public async getResumoPorPeriodo(dtIni, dtFim, tipo){

    if (dtFim === undefined) {
      dtFim = new Date();
    } else {
      dtFim = this.dateConverter.fromNgbDateStructToDate(dtFim);
    }
    if (tipo ===undefined){
      this.alertService.danger('O Tipo Data deve ser preenchido.');
      return;
    }

    if (dtIni === undefined || dtIni === null) {
      this.resumo = new ResumoFluxoFinanceiro();
      this.resumo.execDevolAplic = this.parceriaInfo.execDevolAplic;
      this.resumo.execDevolParceria = this.parceriaInfo.execDevolParceria;
      this.resumo.execDevolRecursos = this.parceriaInfo.execDevolRecursos;
      this.resumo.execSaldoFinal =  this.parceriaInfo.execSaldoFinal;
      this.resumo.execTotIngressos = this.parceriaInfo.execTotIngressos;
      this.resumo.execTotVlrConsumo = this.parceriaInfo.execTotVlrConsumo;
      this.resumo.execTotVlrOutros =  this.parceriaInfo.execTotVlrOutros;
      this.resumo.execTotVlrPF = this.parceriaInfo.execTotVlrPF;
      this.resumo.execTotVlrPJ = this.parceriaInfo.execTotVlrPJ;
      this.resumo.execTotVlrPerm = this.parceriaInfo.execTotVlrPerm;
      this.resumo.totalDesembolsos = this.parceriaInfo.totalDesembolsos;
    }
    else {
      this.loading.next(true);
      this.resumo = await this.detalheParceriaPortalApi.getResumoFluxoFinanceiroPeriodo(this.parceriaInfo.parId,
        this.datePipe.transform(this.dateConverter.fromNgbDateStructToDate(dtIni), 'dd/MM/yyyy'),
        this.datePipe.transform(dtFim, 'dd/MM/yyyy'), tipo).toPromise();
        console.log('socialData ', this.resumo);
    }
  }
  public async  generatePDF(dtIni, dtFim, tipo) {

    await this.getResumoPorPeriodo(dtIni, dtFim, tipo);

    let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF

    pdf.setFont('helvetica');
    pdf.setLineWidth(0.5);
    // let rows;
    // let columns;
    const brasao = new ImagensUrlData().getBrasaoSMF();
    pdf.addImage(brasao, 'JPEG', 5, 4, 33, 13);
    pdf.setFontType('bold');
    pdf.setFontSize(12);
    pdf.text(50, 10, 'Resumo do Fluxo Financeiro da Entidade');
    pdf.setFontType('normal');
    pdf.setFontSize(9);
    const nome = 'ResumoFluxoFinanceiroEntidade_' + this.parceriaInfo.parOrgCnpj + '.pdf';
    pdf.text(40, 20, this.parceriaInfo.parOrgNome.concat(' - ')
    .concat(this.mascaras.aplicaMascaraDocumento(this.parceriaInfo.parOrgCnpj.toString(), 2)));
    pdf.text(160, 15, 'Data/Hora: ' + this.datePipe.transform( Date.now(), 'dd/MM/yyyy HH:mm:ss'));
    pdf.text(40, 24, 'Termo: ' + this.parceriaInfo.parNum);

    if (this.dataIni !== undefined) {
      if (dtFim === undefined) {
        dtFim = Date();
      }else{
        dtFim = this.dateConverter.fromNgbDateStructToDate(dtFim);
      }
      // tslint:disable-next-line:max-line-length
      pdf.text(100, 24, 'Período: ' +   this.datePipe.transform(this.dateConverter.fromNgbDateStructToDate(dtIni), 'dd/MM/yyyy')
      + '  a ' + this.datePipe.transform(dtFim, 'dd/MM/yyyy'));
    }

    pdf.text(20, 40, 'I - Total Ingresso');
    pdf.text(110, 40, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execTotIngressos));

    //pdf.setTextColor(255,0,0);

    pdf.text(20, 45, 'II - Total dos Desembolsos');
    pdf.text(110, 45, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.totalDesembolsos));

    pdf.text(30, 50, 'Consumo');
    pdf.text(80, 50, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execTotVlrConsumo));

    pdf.text(30, 55, 'Pagamento de pessoal');
    pdf.text(80, 55, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execTotVlrPF));

    pdf.text(30, 60, 'Serviços de Terceiros ');
    pdf.text(80, 60, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execTotVlrPJ));

    pdf.text(30, 65, 'Permanente ');
    pdf.text(80, 65, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execTotVlrPerm ));

    pdf.text(30, 70, 'Outros ');
    pdf.text(80, 70, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execTotVlrOutros));

    pdf.text(20, 75, 'III - Devolução para C/C parceria ');
    pdf.text(110, 75, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execDevolParceria ));

    pdf.text(20, 80, 'IV - Ganho/Aplicação e Poupança  ');
    pdf.text(110, 80, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execDevolAplic));

    pdf.text(20, 85, 'V - Rendimentos de conta poupança/provisão ');
    pdf.text(110, 85, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execDevolPoupanca));

    pdf.text(20, 90, 'VI - Rendimentos de outras contas/aplicações financeiras ');
    pdf.text(110, 90, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execDevolOutrasContas));

    pdf.text(20, 95, '(=) Saldo Atual (I - II + III + IV + V + VI)  ');
    pdf.text(110, 95, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execSaldoFinal));

    pdf.text(20, 100, 'VII - Devolução de Recursos  ');
    pdf.text(110, 100, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execDevolRecursos));

    pdf.text(20, 105, '(=) Saldo Final (remanescente) ');
    pdf.text(110, 105, 'R$ ' + this.mascaras.aplicaMascaraReais(this.resumo.execSaldoFinal - this.resumo.execDevolRecursos));

      pdf.save(nome); // Generated PDF
    this.loading.next(false);
  }

  openExportacao(content, resumos) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }


  aplicaMascaraReais(valor) {
    return this.mascaras.aplicaMascaraReais(valor);
  }

  populaTipo(event) {
    const newVal = event.currentTarget.value;
    this.tipo = newVal;
  }

}
