import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable, Subject, merge, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { RelatoriosApi } from '../../apis/relatoriosApi';
import { Routes, Router, RouterModule, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Mascaras } from 'src/app/@shared/components/Mascaras';
import { DateConverter } from 'src/app/@core/date-converter';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { BuscaRelatorioCampos } from '../buscaRelatorioCampos';
import { DespesaRelatorio } from './despesaRelatorio';
import { PaginationInstance } from 'ngx-pagination';
import { DatetimeRange } from 'src/app/@shared/components/datepicker/datatime-range';
import { Utils } from 'src/app/@shared/utils';

@Component({
  selector: 'app-relatorio-desembolso',
  templateUrl: './relatorioDesembolso.component.html',
  styleUrls: ['../../modulo2/exibeParcerias/exibeParceriasM2.component.scss']
})

export class RelatorioDesembolsoComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  selectedParceriaId: number = null;
  despesaArray: Array<DespesaRelatorio>;
  status: number;
  countDespesa: number;
  mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
  busca = new BuscaRelatorioCampos(0, null, null, '', '', '', 1, 0, 0, '1', '', '', 0, 10, null, null, "");
  currentPage: number;
  dataVigenciaInicial: NgbDateStruct;
  collectionSizeParceria: number = 0;
  @Input()
  isPortal: boolean;
  @Input()
  modulo: string;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  dataIni: NgbDateStruct;
  dataFim: NgbDateStruct;
  @Input()
  isAuditorExterno: boolean;
  auditorEmail:string;

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: this.collectionSizeParceria
  };

  constructor(private alertService: AlertService,
    private relatoriosApi: RelatoriosApi,
    public userService: UserService,
    private dateConverter: DateConverter,
    private router: Router,
    private mascara: Mascaras,
    public keycloakService: KeycloakService,
    public utils: Utils,
    private route: ActivatedRoute
  ) {
    this.isConsulta = this.userService.isConsulta();
    this.isFiscal = this.userService.isFiscal();

    this.route.queryParams.subscribe(params => {
      this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
      this.auditorEmail = this.keycloakService['_keycloakInstance'].tokenParsed.email;
    });


    this.keycloakService.getUser().subscribe((user: AuthUser) => {

      //console.log(user.attributes.sgp_orgao[0]);
      if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
        // tslint:disable-next-line:no-unused-expression
        if (this.userService.isSMTC()) {
          this.isSMTC = true;
          this.busca = new BuscaRelatorioCampos(0, null, null, '', '', '', 1, 0, 0, '1', '', '', 0, 10, null, null, "");
        } else {
          this.busca = new BuscaRelatorioCampos(Number(user.attributes.sgp_orgao[0]), null, null, '', '', '', 1, 0, 0, '1', '', '', 0, 10, null, null, null);
        }
      }
      if (this.isAuditorExterno){
        this.busca = new BuscaRelatorioCampos(0, null, null, '', '', '', 1, 0, 0, '1', '', '', 0, 10, null, null, "");
        this.busca.auditorEmail = this.auditorEmail;
      }
      
      this.getDespesaPagina(1);
    });

  }

  ngOnInit() {
    this.modulo = 'relatorio';
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    this.despesaArray = [];

    this.isConsulta = this.userService.isConsulta();
    this.isFiscal = this.userService.isFiscal();

    this.route.queryParams.subscribe(params => {
      this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
    });


    if (!this.isFiscal && !this.isConsulta && !this.isAuditorExterno) {
      this.router.navigate(['notAuthorized']);
    }

  }

  aplicaMascaraDocumento(documento, tipo) {
    return this.mascara.aplicaMascaraDocumento(documento, tipo);
  }

  pageChanged(event) {
    //console.log(event);
    this.config.currentPage = event;
    this.getDespesaPagina(event);
  }

  getDespesaPagina(pagina) {
    this.getDespesa(this.busca, pagina);
  }
  getDespesa(buscaCampos, pagina) {
    buscaCampos.pagina = pagina;
    buscaCampos.limite = 10;
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.relatoriosApi.getDespesaRelatorio(buscaCampos)
      .subscribe(async (response) => {
        if (response) {
          this.despesaArray = response as Array<DespesaRelatorio>;
          await this.getCountDespesa();
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  getExecNaturezaStr(id) {
    if (id == 1) {
      return "Consumo";
    }
    if (id == 2) {
      return "Pagamento de Pessoal";
    }
    if (id == 3) {
      return "Serviços de Terceiros";
    }
    if (id == 4) {
      return "Permanente";
    }
    if (id == 5) {
      return "Outros";
    }
    return "";
  }

  async getCountDespesa() {
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.relatoriosApi.getCountDespesaRelatorio(this.busca)
      .subscribe((response) => {
        if (response) {
          this.countDespesa = response as number;
          this.collectionSizeParceria = Math.ceil((this.countDespesa / 10.0));
          console.log(this.collectionSizeParceria);
          this.config.totalItems = this.countDespesa;
        } else {
          this.alertService.warning('Nenhum registro encontrado');
          this.countDespesa = 0;
          this.collectionSizeParceria = 0;
          this.config.totalItems = this.countDespesa;
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

  }
  selectNatureza(event) {
    const newVal = event.currentTarget.value;
    this.busca.natureza = newVal;
    this.getDespesa(this.busca, 1);
  }
  selectOrgaoParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.orgao = newVal;
    this.getDespesa(this.busca, 1);
  }
  selectNumeroTermo() {
    this.getDespesa(this.busca, 1);
  }
  selectNomeParceria() {
    this.getDespesa(this.busca, 1);
  }
  selectCnpj() {
    this.getDespesa(this.busca, 1);
  }

  novoPeriodo(dataRangeEvent: DatetimeRange) {    
    this.busca.dataInicial = this.dateConverter.fromNgbDateToDate(dataRangeEvent.fromNgbDate);
    this.busca.dataFinal = this.dateConverter.fromNgbDateToDate(dataRangeEvent.toNgbDate);
    this.getDespesa(this.busca, 1);
  }

  geraArquivo(tipo) {

    if (tipo === 'INCO') {
     // this.alertService.primary(' ');
      if (this.busca.dataInicial === null) {
        this.alertService.danger('Preencha uma data inicial. Período máximo aceito para gerar o relatório é igual a 6 meses.');
        return;
      } else {
        if (this.busca.dataFinal === null){
          this.busca.dataFinal = new Date();
        }
        const year1 = this.busca.dataInicial.getFullYear();
        const year2 = this.busca.dataFinal.getFullYear();
        const month1 = this.busca.dataInicial.getMonth();
        const month2 = this.busca.dataFinal.getMonth();
        const numberOfMonths = (year2 - year1) * 12 + (month2 - month1);
        if (numberOfMonths > 6){
          this.alertService.danger('Período máximo aceito para gerar o relatório é igual a 6 meses.');
          return;
        }
      }
      this.busca.tipoArquivo = 'XLS';
      this.busca.nomeRelatorio = 'inconsistencia';
    } else {
      this.busca.tipoArquivo = tipo;
      this.busca.nomeRelatorio = 'despesa';
    }
    this.getFile(this.busca);
  }
  getFile(buscaCampos) {
    this.loading.next(true);
    this.relatoriosApi.getArquivoRelatorio(buscaCampos)
      .subscribe((response) => {
        if (response) {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response);
          a.download = 'relatorio_' + this.busca.nomeRelatorio + '.' + this.busca.tipoArquivo;
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  limparFiltros() {
    this.busca.dataInicial = null;
    this.busca.dataFinal = null;
    this.dataIni = undefined;
    this.dataFim = undefined;
    this.busca.natureza = 1;
    this.busca.filtroOrdenacao = 0;
    this.busca.nomeOrg = '';
    this.busca.numeroTermo = '';
    this.busca.cnpj = '';
    this.busca.tipoArquivo = '';
    this.busca.tipoData = '1';
  }
  selectTipoData(event) {
    const newVal = event.currentTarget.value;
    this.busca.tipoData = newVal;
  }
}
