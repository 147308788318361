<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
    <div class="container-fluid mb-3" style="min-height: 450px;">
        <div class="infoGeral container-fluid">
            <h2>Planilha de Colaboradores</h2>
            <h3>Filtros de pesquisa</h3>
            <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
            <br>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Órgão ou entidade (PMPA)</label>
                        <div class="select-wrapper">
                            <select [disabled]="isFiscal && !isSMTC" class="form-control form-select custom-select"
                                [(ngModel)]="busca.idOrgao" notFoundText=" não encontrado"
                                (change)="selectOrgaoParceria($event)">
                                <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nomeOsc">Nome da OSC</label>
                        <input type="text" [(ngModel)]="busca.nomeOsc" style="width: 100% " id="nomeOsc"
                            class="form-control " [ngModelOptions]="{standalone: true} "
                            placeholder="informe o nome da OSC" [value]="busca.nomeOsc" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cnpjOsc">CNPJ da OSC</label>
                        <input type="text" class="form-control" id="cnpjOsc" [ngModelOptions]="{standalone: true} "
                            [(ngModel)]="busca.cnpjOsc" [value]="busca.cnpjOsc" placeholder="informe o CNPJ da OSC"
                            [textMask]="mascaraCNPJ" />
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="parNum">Nº do termo</label>
                        <input type="text" class="form-control" id="parNum" [ngModelOptions]="{standalone: true} "
                            [(ngModel)]="busca.parNum" [value]="busca.parNum" placeholder="informe o nº do termo" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="modalidade">Modalidade</label>
                        <div class="select-wrapper" style="width: 100%">
                            <select class="form-control form-select custom-select" [(ngModel)]="busca.modalidade"
                                notFoundText=" não encontrada" (change)="selectModalidade($event)">
                                <option value="0">Todas</option>
                                <option [value]="option.id" *ngFor="let option of this.utils.modalidadeOptions">
                                    {{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <label for="statusParceria">Status da Parceria</label>
                    <div class="select-wrapper" style="width: 100%">
                        <select [(ngModel)]="busca.statusParceria" name="statusParceria" notFoundText=" não encontrado"
                            class="form-control form-select custom-select" (change)="selectStatusParceria($event)">
                            <option [value]="option.id" *ngFor="let option of this.utils.statusParceiraOptions">
                                {{option.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nomeFuncionario">Nome do funcionário</label>
                        <input type="text" class="form-control" id="nomeFuncionario"
                            [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeFuncionario"
                            [value]="busca.nomeFuncionario" placeholder="informe o nome do funcionário" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cpfFuncionario">CPF do funcionário</label>
                        <input type="text" class="form-control" id="cpfFuncionario" [ngModelOptions]="{standalone: true} "
                            [(ngModel)]="busca.cpfFuncionario" [value]="busca.cpfFuncionario" placeholder="informe o CPF do funcionário" 
                            (ngModelChange)="onCPFChange()" [textMask]="mascaraCPF"/>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 btn-inline">
                    <div class="form-group">
                        <button class="btn btn-primary" (click)="limparFiltros()">Limpar</button>
                        <button class="btn btn-primary" (click)="geraArquivo()">Gerar Excel</button>
                    </div>
                </div>
            </div>


            <!-- Adicionar a tabela informativa que só aparece se cpfFuncionario estiver preenchido -->
            <div *ngIf="busca.cpfFuncionario && colaborador">
                <h3>Informações do Funcionário</h3>
                <div class="form-row">
                    <!-- Alinha as labels e os dados lado a lado -->
                    <div class="col-md-6">
                        <label>Nome da Organização:</label> {{ colaborador.colaboradorNomeOsc }}
                    </div>
                    <div class="col-md-6">
                        <label>CNPJ da Organização:</label> {{ formatarCNPJ(colaborador.colaboradorCnpjOsc) }}
                    </div>
                    <div class="col-md-6">
                        <label>Nome Funcionário:</label> {{ colaborador.colaboradorNome }}
                    </div>
                    <div class="col-md-6">
                        <label>CPF Funcionário:</label> {{ formatarCPF(colaborador.colaboradorCpf) }}
                    </div>
                    <div class="col-md-6">
                        <label>Endereço:</label> {{ colaborador.colaboradorEndereco }}
                    </div>
                    <div class="col-md-6">
                        <label>Cargo:</label> {{ colaborador.colaboradorCargo }}
                    </div>
                    <div class="col-md-6">
                        <label>Data de Admissão:</label> {{ dataAdmissaoStr }}
                    </div>
                    <div class="col-md-6">
                        <label>Data de Demissão:</label> {{ dataDemissaoStr }}
                    </div>
                    <div class="col-md-6">
                        <label>Salário Mensal:</label> {{ colaborador.colaboradorSalarioMensal | currency:'BRL':'symbol':'1.2-2' }}
                    </div>
                    <div class="col-md-6">
                        <label>Salário Bruto:</label> {{ colaborador.colaboradorSalarioBruto | currency:'BRL':'symbol':'1.2-2' }}
                    </div>
                    <div class="col-md-6">
                        <label>Vínculo:</label> {{ getVinculoPorExtenso() }}
                    </div>
                    <div class="col-md-6">
                        <label>Nível de Escolaridade:</label> {{ getEscolaridadePorExtenso(colaborador.colaboradorEscolaridade)}}
                    </div>
                    <div class="col-md-6">
                        <label>Nome do Curso/Formação:</label> {{ colaborador.colaboradorNomeCurso }}
                    </div>
                    <div class="col-md-6">
                        <label>Nº Registro em Conselho:</label> {{ colaborador.colaboradorNumRegConselho }}
                    </div>
                    <div class="col-md-6">
                        <label>Data da Habilitação:</label> {{ dataHabilitacaoStr}}
                    </div>
               </div>
            </div>




            <div [hidden]="!(loading$ | async) ">
                <div class="loading-overlay "></div>
                <div class="loading-info ">
                    <i class="fa fa-spinner fa-4x fa-spin "></i>
                    <div id="carregando ">Aguarde</div>
                </div>
            </div>
        </div>
    </div>
</div>