import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ColaboradorCargaHoraria } from "./colaboradorCargaHoraria";

export class Colaborador {
    public id: number;
    public colaboradorCnpjOsc: string;
    public colaboradorCpf: string;
    public colaboradorNome: string;
    public colaboradorEndereco: string;
    public colaboradorCargo: string;
    public colaboradorSalarioMensal: number;
    public colaboradorVinculo: number;
    public colaboradorVinculoOutro: string;
    public colaboradorVinculoTexto: string;
	public colaboradorResponsavelOSC: number;
    public colaboradorResponsavelOSCChecked: Boolean;
    public colaboradorOperacaoData: Date;
    public colaboradorDataAdmissao: Date;
    public colaboradorDataDemissao: Date;
    public colaboradorDataAdmissaoDataFormat: NgbDateStruct;
    public colaboradorDataDemissaoDataFormat: NgbDateStruct;
    public colaboradorEscolaridade: number;
    public colaboradorNomeCurso: string;
    public colaboradorNumRegConselho: string;
    public colaboradorDataHabilitacao: Date;
    public colaboradorDataHabilitacaoDataFormat: NgbDateStruct;
    public colaboradorDocumento: Blob;
    public colaboradorDocumentoArquivo: string;
    public colaboradorDocumentoTipo: string;
    public colaboradorFuncaoDiretiva: string;
    public colaboradorInicioMandato: Date;
    public colaboradorFimMandato: Date;
    public colaboradorInicioMandatoDataFormat: NgbDateStruct;
    public colaboradorFimMandatoDataFormat: NgbDateStruct;
    public cargasHorarias: Array<ColaboradorCargaHoraria>;
    public idsCargasHorariasAExcluir: Array<number>;
    public colaboradorSalarioBruto:number;
    public colaboradorCBO:number;
    public colaboradorTipoAtividade:number;
    public colaboradorTermoRescisaoNome: string;
    public colaboradorTermoRescisaoTipo: string;
    public colaboradorTermoRescisaoArquivo:Blob;
    public colaboradorNomeOsc: string;

}