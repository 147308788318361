import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { RelatoriosApi } from '../../apis/relatoriosApi';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { BuscaRelatorioNotificacaoCampos } from './buscaRelatorioNotificacaoCampos';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BuscaTermosRelatorioNotificacoesCampos } from './buscaTermosRelatorioNotificacoesCampos';
import { BuscaNotificacoesRelatorioNotificacoesCampos } from './buscaNotificacoesRelatorioNotificacoesCampos';
//import { element } from '@angular/core/src/render3/instructions';
import { Utils } from 'src/app/@shared/utils';

@Component({
    selector: 'app-relatorio-notificacoes',
    templateUrl: './relatorioNotificacoes.component.html',
    styleUrls: ['../../modulo2/exibeParcerias/exibeParceriasM2.component.scss']
})

export class RelatorioNotificacoesComponent implements OnInit {
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    selectedParceriaId: number = null;
    status: number;
    mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
    busca: BuscaRelatorioNotificacaoCampos = new BuscaRelatorioNotificacaoCampos(1, 0, [], [], [], [], [], [], '', null, null, "");
    @Input()
    isPortal: boolean;
    @Input()
    modulo: string;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    arquivo: string;
    isAuditorExterno: boolean;
    auditorEmail: string;
    dataIni: NgbDateStruct;
    dataFim: NgbDateStruct;
    dropdownSettings: IDropdownSettings = {
        selectAllText: "Selecionar Tudo",
        unSelectAllText: "Limpar Seleção",
        idField: 'id',
        textField: 'name',
    };
    parIds = [];
    cnpjOscs = [];
    modalidades = [];
    situacoes = [];
    notificacaoIds = [];
    usuarios = [];
    situacoesNotificacaoList = [];
    situacoesNotificacaoItemList = [];
    dropdownOscList = [];
    dropdownModalidadeList = [];
    dropdownSituacaoList = [];
    dropdownTermoList = [];
    dropdownNotificacaoList = [];
    dropdownUsuarioList = [];

    constructor(private alertService: AlertService,
        private relatoriosApi: RelatoriosApi,
        private detalheParceriaPortalApi: DetalheParceriaApi,
        public userService: UserService,
        private dateConverter: DateConverter,
        private router: Router,
        public keycloakService: KeycloakService,
        public utils: Utils,
        private route:ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.modulo = 'relatorio';
        if (this.isPortal === undefined) {
            this.isPortal = false;
        }

        this.isConsulta = this.userService.isConsulta();
        this.isFiscal = this.userService.isFiscal();

        this.route.queryParams.subscribe(params => {
            this.isAuditorExterno = params['isAuditorExterno'];
            this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
            this.auditorEmail = this.keycloakService['_keycloakInstance'].tokenParsed.email;
        });
      

        if (!this.isFiscal && !this.isConsulta && !this.isAuditorExterno) {
            this.router.navigate(['notAuthorized']);
        }
        // pegar qualificador
        this.keycloakService.getUser().subscribe((user: AuthUser) => {

            //console.log(user.attributes.sgp_orgao[0]);
            if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                    this.isSMTC = true;
                    this.busca = new BuscaRelatorioNotificacaoCampos(1, 0, [], [], [], [], [], [], '', null, null, "");
                } else {
                    this.busca = new BuscaRelatorioNotificacaoCampos(1, Number(user.attributes.sgp_orgao[0]), [], [], [], [], [], [], '', null, null, "");
                }
            }

            if (this.isAuditorExterno) {
                this.busca.auditorEmail = this.auditorEmail;
            }
            this.carregaComboboxOSCs();
            this.carregaComboboxTermos();
            this.carregaComboboxSituacoes();
            this.carregaComboboxNotificacoes();
            this.carregaComboboxUsuarios();
        });
    }

    geraArquivo(tipo) {
        this.busca.tipoArquivo = tipo;
        if (this.dataIni) {
            this.busca.dataInicio = this.dateConverter.fromNgbDateStructToDate(this.dataIni);
        }
        if (this.dataFim) {
            this.busca.dataFim = this.dateConverter.fromNgbDateStructToDate(this.dataFim);
        }

        console.log(this.busca);
        this.relatoriosApi.getArquivoRelatorioNotificacoes(this.busca).subscribe((response) => {
            if (response) {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(response);
                a.download = `relatorio_${this.busca.tipoRelatorio == 1 ? 'notificacoes' : (this.busca.tipoRelatorio == 2 ? 'apontamentos_notificacoes' : 'analises_notificacoes')}.${this.busca.tipoArquivo}`;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

            } else {
                this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    selectTipoRelatorio(event) {
        const newVal = event.currentTarget.value;
        this.busca.tipoRelatorio = newVal;
        this.carregaComboboxSituacoes();
    }

    carregaComboboxOSCs() {
        this.relatoriosApi.getOscsCombobox(this.busca.orgao).subscribe((response) => {
            if (response) {
                this.dropdownOscList = [];
                response.forEach(element => {
                    this.dropdownOscList.push({ id: element[0], name: element[1] });
                });
            }
        })
    }

    onOscSelect(event) {
        this.busca.cnpjOscs.push(event.id);
        this.carregaComboboxTermos();
    }

    onOscDeSelect(event) {
        this.busca.cnpjOscs.forEach((element, index) => {
            if (element == event.id) {
                this.busca.cnpjOscs.splice(index, 1);
            }
        });
        this.carregaComboboxTermos();
    }

    onSelectAllOsc(event) {
        event.forEach((element) => {
            this.busca.cnpjOscs.push(element.id);
        })
        this.carregaComboboxTermos();
    }

    onOscDeSelectAll(event) {
        this.busca.cnpjOscs = [];
        this.carregaComboboxTermos();
    }

    onModalidadeSelect(event) {
        this.busca.modalidades.push(event.id);
    }

    onModalidadeDeSelect(event) {
        this.busca.modalidades.forEach((element, index) => {
            if (element == event.id) {
                this.busca.modalidades.splice(index, 1);
            }
        });
    }

    onSelectAllModalidade(event) {
        event.forEach((element) => {
            this.busca.modalidades.push(element.id);
        })
    }

    onModalidadeDeSelectAll(event) {
        this.busca.modalidades = [];
    }

    carregaComboboxTermos() {
        var buscaTermos = new BuscaTermosRelatorioNotificacoesCampos();
        buscaTermos.orgao = this.busca.orgao;
        buscaTermos.cnpjs = this.busca.cnpjOscs;

        this.relatoriosApi.getTermosCombobox(buscaTermos).subscribe((response) => {
            if (response) {
                this.dropdownTermoList = [];
                this.parIds = [];
                this.busca.parIds = [];
                response.forEach(element => {
                    this.dropdownTermoList.push({ id: element[0], name: element[1] });
                });
            }
        })
    }

    onTermoSelect(event) {
        this.busca.parIds.push(event.id);
        this.carregaComboboxNotificacoes();
    }

    onSelectAllTermo(event) {
        event.forEach(element => {
            this.busca.parIds.push(element.id);
        });
        this.carregaComboboxNotificacoes();
    }

    onTermoDeSelect(event) {
        this.busca.parIds.forEach((element, index) => {
            if (element == event.id) {
                this.busca.parIds.splice(index, 1);
            }
        })
        this.carregaComboboxNotificacoes();
    }

    onTermoDeSelectAll(event) {
        this.busca.parIds = [];
        this.carregaComboboxNotificacoes();
    }

    carregaComboboxSituacoes() {
        this.situacoes = [];
        this.busca.situacoes = [];
        console.log(this.busca.tipoRelatorio);
        if (this.busca.tipoRelatorio == 1) {
            this.dropdownSituacaoList = [
                { id: 1, name: 'Salva' },
                { id: 2, name: 'Enviada' },
                { id: 999, name: 'Em atraso' },
                { id: 3, name: 'Respondida' },
                { id: 4, name: 'Concluída' },
                { id: 5, name: 'Reaberta' },
            ];
        } else {
            this.dropdownSituacaoList = [
                { id: 1, name: 'Pendente' },
                { id: 2, name: 'Atendido' },
                { id: 3, name: 'Mantido' },
                { id: 4, name: 'Baixado' }
            ];
        }
    }

    onSituacaoSelect(event) {
        this.busca.situacoes.push(event.id);
    }

    onSelectAllSituacao(event) {
        event.forEach((element) => {
            this.busca.situacoes.push(element.id);
        })
    }

    onSituacaoDeSelect(event) {
        this.busca.situacoes.forEach((element, index) => {
            if (element == event.id) {
                this.busca.situacoes.splice(index, 1);
            }
        })
    }

    onSituacaoDeSelectAll(event) {
        this.busca.situacoes = [];
    }

    carregaComboboxNotificacoes() {
        var buscaNotificacoes = new BuscaNotificacoesRelatorioNotificacoesCampos();
        buscaNotificacoes.orgao = this.busca.orgao;
        buscaNotificacoes.parIds = this.busca.parIds;

        this.relatoriosApi.getNotificacoesCombobox(buscaNotificacoes).subscribe((response) => {
            if (response) {
                this.dropdownNotificacaoList = [];
                this.notificacaoIds = [];
                this.busca.notificacaoIds = [];
                response.forEach(element => {
                    this.dropdownNotificacaoList.push({ id: element[0], name: element[1] });
                })
            }
        })
    }

    onNotificacaoSelect(event) {
        this.busca.notificacaoIds.push(event.id);
        this.carregaComboboxUsuarios();
    }

    onSelectAllNotificacao(event) {
        event.forEach(element => {
            this.busca.notificacaoIds.push(element.id);
        });
        this.carregaComboboxUsuarios();
    }

    onNotificacaoDeSelect(event) {
        this.busca.notificacaoIds.forEach((element, index) => {
            if (element == event.id) {
                this.busca.notificacaoIds.splice(index, 1);
            }
        })
        this.carregaComboboxUsuarios();
    }

    onNotificacaoDeSelectAll(event) {
        this.busca.notificacaoIds = [];
        this.carregaComboboxUsuarios();
    }

    
    carregaComboboxUsuarios() {
        var buscaNotificacoes = new BuscaNotificacoesRelatorioNotificacoesCampos();
        buscaNotificacoes.orgao = this.busca.orgao;
        buscaNotificacoes.parIds = this.busca.parIds;
        buscaNotificacoes.tipoRelatorio = this.busca.tipoRelatorio;

        this.relatoriosApi.getUsuariosCombobox(buscaNotificacoes).subscribe((response) => {
            if (response) {
                this.dropdownUsuarioList = [];
                this.usuarios = [];
                this.busca.usuarios = [];
                response.forEach(element => {
                    this.dropdownUsuarioList.push({ id: element, name: element });
                })
            }
        })
    }

    onUsuarioSelect(event) {
        this.busca.usuarios.push(event.id);
    }

    onSelectAllUsuario(event) {
        event.forEach(element => {
            this.busca.usuarios.push(element.id);
        });
    }

    onUsuarioDeSelect(event) {
        this.busca.usuarios.forEach((element, index) => {
            if (element == event.id) {
                this.busca.usuarios.splice(index, 1);
            }
        })
    }

    onUsuarioDeSelectAll(event) {
        this.busca.usuarios = [];
    }

    limparFiltros(){
        this.busca.tipoRelatorio = 1;
        this.cnpjOscs = [];
        this.modalidades = [];
        this.parIds = [];
        this.situacoes = [];
        this.notificacaoIds = [];
        this.usuarios = [];
        this.dataIni = null;
        this.dataFim = null;
    }
}